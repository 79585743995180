import './styles.scss'
import { CircularProgress } from '@mui/material';

const LoadingOverlay = () => {
    return (
        <div className='loader-wrapper'>
            <CircularProgress color='inherit'/>
        </div>
    );
};

export default LoadingOverlay;
