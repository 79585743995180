import React from 'react';
import { ReactComponent as ListIcon } from 'assets/icons/check-list.svg';

const List = ({ lists, color }) => {
    return (
        color ?
            <ul>
                {lists?.map((list, index) => (
                    <li key={index}>
                        <ListIcon className="list-icon" style={{ color: color }} />
                        <div className="body2">{list.text}</div>
                    </li>
                ))}
            </ul>
            :

            <ul>
                {lists?.map((list, index) => (
                    <li key={index}>
                        <ListIcon className="list-icon" style={{ color: list.icon_color }} />
                        <div className="body2">{list.text}</div>
                    </li>
                ))}
            </ul>
    );
};

export default List;

