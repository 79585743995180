import { useEffect, useState } from 'react';

export const useImageLoaded = (dependencies, isLoading, isObserver) => {
    const [isImageLoading, setIsImageLoading] = useState(false);

    useEffect(() => {
        if (!isLoading) {
            const images = document.querySelectorAll('img');
            let loadedImagesCount = 0;

            const handleImageLoad = () => {
                loadedImagesCount++;
                if (loadedImagesCount === images.length) {
                    setIsImageLoading(true);
                }
            };

            const handleImages = (images) => {
                images.forEach(image => {
                    if (image.complete) {
                        handleImageLoad();
                    } else {
                        image.addEventListener('load', handleImageLoad);
                    }
                });
            }

            // Add load listeners to images
            handleImages(images);

            // Use MutationObserver to watch for changes in the DOM
            const observer = new MutationObserver(() => {
                const newImages = document.querySelectorAll('img');
                loadedImagesCount = 0; // Reset count for new images

                // Add listeners for new images
                handleImages(newImages)
            });

            if (isObserver) {
                // Start observing the DOM for changes (additions/removals of image elements)
                observer.observe(document.body, {
                    childList: true,
                    subtree: true,
                });
            }

            return () => {
                // Cleanup listeners and stop observing the DOM
                images.forEach(image => {
                    image.removeEventListener('load', handleImageLoad);
                });
                observer.disconnect();
            };
        }
    }, [dependencies, isLoading, isObserver]);

    return isImageLoading;
};

// import { useEffect, useRef, useState } from "react";

// export const useImageLoaded = () => {
//     const [isImageLoading, setIsImageLoading] = useState(true);
//     const imagesRef = useRef([]);

//     useEffect(() => {
//         const handleImageLoad = () => {
//             const loadedImages = imagesRef.current.filter(image => image.complete);
//             if (loadedImages.length === imagesRef.current.length) {
//                 setIsImageLoading(false);
//             }
//         };

//         const images = Array.from(document.getElementsByTagName('img'));
//         imagesRef.current = images;

//         images.forEach(image => {
//             if (image.complete) {
//                 handleImageLoad();
//             } else {
//                 image.addEventListener('load', handleImageLoad);
//             }
//         });

//         return () => {
//             images.forEach(image => {
//                 image.removeEventListener('load', handleImageLoad);
//             });
//         };
//     }, []);

//     return isImageLoading;
// };
