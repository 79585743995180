import Button from 'components/common/Buttons/index';
import HeroTrustpilot from 'components/Sections/Hero/HeroTrustpilot';
import HeroCrumbs from 'components/Sections/Hero/HeroCrumbs';
// import { scrollToSection } from 'helpers';
import { useAnimationWithWithoutScroll } from 'hooks/useAnimation';
import { useRef } from 'react';
import GoogleButton from 'components/common/Buttons/GoogleButton';
import { selectLandingPageTags } from 'store/slices/LandingSlice';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const HeroComponent = ({
    title,
    desc,
    button_text,
    button_two_text,
    button_text_link,
    button_two_text_link,
    hasCrumbs,
    isLoading,
}) => {
    const container = useRef();

    const landingTags = useSelector(selectLandingPageTags);

    // const handleClick = () => {
    //     scrollToSection("product-price")
    // }

    useAnimationWithWithoutScroll({
        item: '.hero-body',
        container,
        dependencies: [isLoading],
        isLoading: isLoading,
    });
    useAnimationWithWithoutScroll({
        item: '.hero-trustpilot',
        container,
        dependencies: [isLoading],
        isLoading: isLoading,
    });
    useAnimationWithWithoutScroll({
        item: '.hero-body-buttons',
        container,
        dependencies: [isLoading],
        isLoading: isLoading,
    });
    return (
        <div ref={container} className={`hero-container ${hasCrumbs ? 'hero-container--with-crumbs' : ''}`}>
            {hasCrumbs && <HeroCrumbs page={title} />}
            <HeroTrustpilot />
            <div className="hero-body">
                <h1 className="hero-body__title">{title}</h1>
                <div className="body1 hero-body__desc">{desc}</div>
            </div>
            <div className="hero-body-buttons">
                <div className="hero-body-buttons">
                    <Link to={process.env.REACT_APP_DASHBOARD_URL} aria-label="Go to dashboard">
                        <Button text={button_text} />
                    </Link>
                    {landingTags.start_with_google_button && <GoogleButton />}
                </div>
            </div>
        </div>
    );
};

export default HeroComponent;
