import './styles.scss';
import Partner from './Partner'
import { useRef } from 'react';
import { useAnimation } from 'hooks/useAnimation';

const TrustedPartners = ({ data, isLoading }) => {

    const container = useRef();

    useAnimation({
        items: ['.trusted-partners__title', '.trusted-partners-item'],
        container,
        dependencies: [isLoading],
    })
    const { logo, title } = data
    return (
        <section ref={container} className="trusted-partners">
            <div className='inner-container'>
                <div className="body2 trusted-partners__title">{title}</div>
                <div className='trusted-partners__container'>
                    {
                        logo?.map((icon, index) => <Partner key={index} icon={icon} />)
                    }
                </div>
            </div>
        </section>
    );
}

export default TrustedPartners;