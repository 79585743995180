import axios from 'axios';
import config from 'api/urls-config.json';
import { API } from 'api/constants';

export const getUseCases = () => {
    return axios.get(API + config.pages.use_cases);
};


export const getUseCasesBySlag = params => {
    const { useCasesSlug } = params;
    return axios.get(
        API + `/use-cases?filters[slug][$eq]=${useCasesSlug}&populate=hero.image&populate=partners.logo.image&populate=product_plans.product_card.icon&populate=product_plans.product_card.list_items&populate=information_section.questions&populate=reviews&populate=cta&populate=meta_tags.tags`,
    );
};


