import axios from 'axios';
import config from 'api/urls-config.json';
import { API } from 'api/constants';

export const getPricing = () => {
    return axios.get(`${API}${config.pages.pricing}`);
};

export const getProductsData = () => {
    return axios.get(`${process.env.REACT_APP_PING_API_BASE_URL}/1.0/private/user/product/search`);
};
