import './styles.scss'
import { useAnimationWithWithoutScroll } from 'hooks/useAnimation';
import { useRef } from 'react';


const HeroLayout = ({ children, image, isLoading }) => {
    const container = useRef();



    useAnimationWithWithoutScroll({
        item: ['.hero-main-img', ".hero-component__left"],
        container,
        dependencies: [isLoading],
        isLoading: isLoading
    })

    return (
        <section ref={container} className="hero-container hero">
            <div className="hero-component__left" style={{ opacity: isLoading ? 0 : 1 }}>{children}</div>
            <div className="hero-component__right" >
                <div className='hero-main-img' style={{ opacity: isLoading ? 0 : 1 }}>
                    {
                        image?.props.src !== "error" && image
                    }
                    {/* <img src={image} alt="" /> */}

                </div>
            </div>
        </section >
    )
};

export default HeroLayout;