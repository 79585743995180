import TrustedPartners from 'components/Sections/TrustedPartners';
import OurProducts from 'components/Sections/OurProducts/';
import Reviews from 'components/Sections/Reviews';
import GetStarted from 'components/Sections/GetStarted';
import RadioFAQ from 'components/Sections/RadioFAQ';
import Hero from './Hero';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectLandingPagePartners,
    selectLandingPageProducts,
    selectLandingPageReviews,
    selectLandingPageCta,
    selectLandingPageIsLoading,
    fetchCurrentLandingPage,
    selectLandingPage,
    selectLandingPageInformations,
} from 'store/slices/LandingSlice';
import { useEffect, useState } from 'react';
import { useImageLoaded } from 'hooks/useImageLoaded';
import { useNavigate, useParams } from 'react-router-dom';

const LandingPage = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const isLoadedImages = useImageLoaded();
    const { landingPageSlug } = useParams();
    const navigate = useNavigate();

    const data = useSelector(selectLandingPage);
    const partners = useSelector(selectLandingPagePartners);
    const { title, product_card, tag } = useSelector(selectLandingPageProducts);
    const reviews = useSelector(selectLandingPageReviews);
    const cta = useSelector(selectLandingPageCta);
    const landingIsLoading = useSelector(selectLandingPageIsLoading);
    const informations = useSelector(selectLandingPageInformations);

    useEffect(() => {
        if (landingPageSlug) {
            // @ts-ignore
            dispatch(fetchCurrentLandingPage({ landingPageSlug }));
        }
    }, [dispatch, landingPageSlug]);

    useEffect(() => {
        if (isLoadedImages && !landingIsLoading) {
            setIsLoading(false);
        }
    }, [isLoadedImages, landingIsLoading]);

    if (!landingIsLoading && !data) {
        navigate('*');
        return null;
    }

    return (
        !landingIsLoading && (
            <>
                <Hero isLoading={isLoading} />
                <TrustedPartners data={partners} isLoading={isLoading} />
                <OurProducts title={title} tag={tag} products={product_card} isLoading={isLoading} />
                <RadioFAQ isLoading={isLoading} data={informations} />
                <Reviews reviews={reviews} isLoading={isLoading} />
                <GetStarted cta={cta} isLoading={isLoading} />
            </>
        )
    );
};

export default LandingPage;
