import axios from 'axios';
import config from 'api/urls-config.json';
import { API } from 'api/constants';

export const getLandingPage = () => {
    return axios.get(API + config.pages.landing_page);
};


export const getLandingPageBySlag = params => {
    const { landingPageSlug } = params;
    return axios.get(
        API + `/landing-pages?filters[slug][$eq]=${landingPageSlug}&populate=hero.image&populate=partners.logo.image&populate=product_plans.product_card.icon&populate=product_plans.product_card.list_items&populate=information_section.questions&populate=reviews&populate=cta&populate=meta_tags.tags`,
    );
};


