import React, { useState, useEffect } from 'react';

const BasicAuth = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authenticate = () => {
      const password = process.env.REACT_APP_BASIC_AUTH_PASSWORD;

      if (!password) {
        setIsAuthenticated(true);
        return;
      }

      const authHeader = localStorage.getItem('basicAuthHeader');
      if (authHeader) {
        // Verify the stored auth header against the current password
        if (authHeader === btoa(password)) {
          setIsAuthenticated(true);
          return;
        } else {
          // If the stored header doesn't match, remove it
          localStorage.removeItem('basicAuthHeader');
        }
      }

      const inputPassword = prompt('Please enter the password:');
      if (inputPassword) {
        if (inputPassword === password) {
          localStorage.setItem('basicAuthHeader', btoa(password));
          setIsAuthenticated(true);
        } else {
          alert('Invalid password');
          authenticate(); // Prompt again if password is incorrect
        }
      } else {
        // If user cancels the prompt, we should not authenticate
        setIsAuthenticated(false);
      }
    };

    authenticate();
  }, []);

  if (!isAuthenticated) {
    return null;
  }

  return children;
};

export default BasicAuth;