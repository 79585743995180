import './styles.scss';
import { ReactComponent as Arrow } from 'assets/icons/button/arrow.svg';
import CheckBox from 'components/common/checkBox';
import React, { useEffect, useRef, useState } from 'react';
import Button from '../common/Buttons/index';
import { Link } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { selectContactForm } from 'store/slices/GeneralSlice';
import { useSelector } from 'react-redux';
import { getImageUrl } from 'helpers';
import CheckSquare from 'assets/icons/CheckSquare.svg'
import { postContactForm } from 'api/General';
import useAnalyticsEventTracker from 'hooks/useAnalyticsEventTracker';



const ContactForm = () => {
    const [isSent, setSent] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        company: '',
        companyEmail: '',
        phoneNumber: '',
        selectedCountry: null,
        message: '',
        termsCheck: false,
    });

    const [formErrors, setFormErrors] = useState({
        fullName: '',
        company: '',
        companyEmail: '',
        // phoneNumber: '',
        selectedCountry: '',
        message: '',
    });

    const [checkboxValid, setCheckboxValid] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const contentRef = useRef(null);
    const [height, setHeight] = useState('0px');

    const {
        name_title,
        name_placeholder,
        company_title,
        company_placeholder,
        company_email_title,
        company_email_placeholder,
        phone_title,
        phone_placeholder,
        country_title,
        message_title,
        message_placeholder,
        terms_conditions_text,
        submit_text,
        countries,
        success_title,
        success_description,
    } = useSelector(selectContactForm);


    const toggleDropdown = (event) => {
        event.stopPropagation();
        setDropdownOpen(!dropdownOpen);
        setHeight(dropdownOpen ? '0px' : `255px`);
    };

    const handleInputChange = (field, value) => {
        setFormData((prevFormData) => ({ ...prevFormData, [field]: value }));
        setFormErrors((prevFormErrors) => ({ ...prevFormErrors, [field]: value ? '' : `Please enter ${field}` }));
    };
    const handleInputChangeOnlyText = (field, value) => {
        value = value.replace(/[^a-zA-Z\s]/g, '');
        setFormData((prevFormData) => ({ ...prevFormData, [field]: value }));
        setFormErrors((prevFormErrors) => ({ ...prevFormErrors, [field]: value ? '' : `Please enter ${field}` }));
    };
    const handleCountryChange = (country) => {
        setFormData((prevFormData) => ({ ...prevFormData, selectedCountry: country }));
        setDropdownOpen(false);
        setHeight('0px');
    };

    const handleCheckboxChange = (isChecked) => {
        setFormData((prevFormData) => ({ ...prevFormData, termsCheck: isChecked }));
        setCheckboxValid(true);
    };

    const eventTracker = useAnalyticsEventTracker({ category: "User", action: "Submit Contact Form" });

    const handleSubmit = () => {
        if (!isEmailValid(formData.companyEmail)) {
            setFormErrors((prevFormErrors) => ({
                ...prevFormErrors,
                companyEmail: 'Please enter a valid email address',
            }));
            return
        }
        if (!formData.termsCheck) {
            setCheckboxValid(false)
            return
        }
        eventTracker();
        sendEmail();
    };
    const isEmailValid = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isFormValid = () => {
        return (
            formData.fullName &&
            formData.company &&
            formData.companyEmail &&
            formData.selectedCountry &&
            formData.message
        );
    };

    const sendEmail = async () => {
        const data = {
            data: {
                "name": formData.fullName,
                "company": formData.company,
                "email": formData.companyEmail,
                "phone": formData.phoneNumber,
                "country": formData.selectedCountry.name,
                "message": formData.message
            }
        };

        postContactForm({ data })
            .then(response => {
                setSent(true)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                setDropdownOpen(false);
                setHeight('0px');
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [contentRef]);


    return isSent ?
        (
            <div className='contact-form-success'>
                <div className='contact-form-success__img'>
                    <img src={CheckSquare} alt="Success" />
                </div>
                <h3 className="contact-form-success__title">{success_title}</h3>
                <div className="body2 contact-form-success__desc">{success_description}</div>
            </div>
        )
        :
        (
            <div className="contact-form">

                <div className="col-6">
                    <label htmlFor="fullName">{name_title}</label>
                    <input
                        id="fullName"
                        type="text"
                        placeholder={name_placeholder}
                        value={formData.fullName}
                        onChange={(e) => handleInputChangeOnlyText('fullName', e.target.value)}
                        className={formErrors.fullName ? 'error' : ''}
                        required
                    />
                    {formErrors.fullName && <div className="body3 error-message">Please enter {name_title}</div>}

                </div>
                <div className="col-6">
                    <label htmlFor="Company">{company_title}</label>
                    <input
                        id="Company"
                        type="text"
                        placeholder={company_placeholder}
                        value={formData.company}
                        onChange={(e) => handleInputChange('company', e.target.value)}
                        className={formErrors.company ? 'error' : ''}

                        required
                    />
                    {formErrors.company && <div className="error-message">Please enter {company_title}</div>}

                </div>
                <div className="col-6">
                    <label htmlFor="CompanyEmail">{company_email_title}</label>
                    <input
                        id="CompanyEmail"
                        type="email"
                        inputMode="email"
                        placeholder={company_email_placeholder}
                        value={formData.companyEmail}
                        onChange={(e) => handleInputChange('companyEmail', e.target.value)}
                        className={formErrors.companyEmail ? 'error' : ''}
                        required
                    />
                    {formErrors.companyEmail && <div className="error-message">Please enter {company_email_title}</div>}
                </div>
                <div className="col-6">
                    <label htmlFor="Phonenumber">{phone_title}</label>
                    <input
                        id="Phonenumber"
                        type="tel"
                        placeholder={phone_placeholder}
                        value={formData.phoneNumber}
                        inputMode="tel"
                        onChange={(e) => {
                            let sanitizedValue = e.target.value.replace(/[^0-9+]/g, '');
                            if (sanitizedValue.startsWith('+')) {
                                sanitizedValue = '+' + sanitizedValue.substring(1).replace(/\+/g, '');
                            } else {
                                sanitizedValue = sanitizedValue.replace(/\+/g, '');
                            }
                            handleInputChange('phoneNumber', sanitizedValue);
                        }}
                    // className={formErrors.phoneNumber ? 'error' : ''}
                    />
                    {/* {formErrors.phoneNumber && <div className="error-message">Please enter {phone_title}</div>} */}
                </div>
                <div className="col-12">
                    <label htmlFor="Country">{country_title}</label>
                    <div className={`contact-form-dropdown ${dropdownOpen ? 'open' : 'closed'}`} onClick={toggleDropdown}>
                        <div className={` contact-form-select ${dropdownOpen ? 'open' : 'closed'} ${formErrors.selectedCountry ? 'error' : ''}`}>
                            {formData.selectedCountry ? (
                                <div className=" contact-form-select-country">
                                    <img className="contact-form-flag" src={getImageUrl(formData.selectedCountry.icon)} alt={formData.selectedCountry.name} />
                                    {formData.selectedCountry.name}
                                </div>
                            ) : (
                                'Select a country'
                            )}{' '}
                            <Arrow className="arrow" />
                        </div>
                        <div
                            ref={contentRef}
                            style={{ maxHeight: `${height}` }}
                            className={` contact-form-options ${dropdownOpen ? 'open' : 'closed'}`}
                        >
                            {countries?.map((country, index) => (
                                <div
                                    key={index}
                                    className="contact-form-options-item"
                                    onClick={() => handleCountryChange(country)}
                                >
                                    <img className="contact-form-flag" src={getImageUrl(country.icon)} alt={country.name} />
                                    <span>{country.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    {formErrors.selectedCountry && <div className="error-message">Please enter {country_title}</div>}

                </div>
                <div className="col-12">
                    <label htmlFor="describeYourUse">{message_title}</label>
                    <textarea
                        id="describeYourUse"
                        placeholder={message_placeholder}
                        value={formData.message}
                        onChange={(e) => handleInputChange('message', e.target.value)}
                        className={formErrors.message ? 'error' : ''}
                        required
                    ></textarea>
                    {formErrors.message && <div className="error-message">Please enter {message_title}</div>}

                </div>
                <div className="col-12 contact-form-agree">
                    <div className="contact-form-agree__check">
                        <CheckBox
                            id={'termsCheck'}
                            type="purple"
                            error={checkboxValid}
                            checked={formData.termsCheck}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="termsCheck">
                            <div className="body4">
                                {terms_conditions_text}
                                <Link to={ROUTES.terms_and_conditions}>terms and conditions</Link>
                            </div>
                        </label>
                    </div>
                    <Button text={submit_text} hasArrow={false} onClick={handleSubmit} disabled={!isFormValid()} />
                </div>
            </div>
        );


};

export default ContactForm;
