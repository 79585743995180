import axios from 'axios';
import config from 'api/urls-config.json';
import { API, posts_per_request } from 'api/constants';

export const getBlogPage = () => {
    return axios.get(API + config.pages.blog);
};

export const getFilterTags = () => {
    return axios.get(API + config.tags);
};

export const getPosts = params => {
    const { type, page, pageSize = posts_per_request } = params;

    return axios.get(
        API +
        config.posts +
        `?populate=*${type ? `&filters[tags][name][$eq]=${type}` : ''
        }&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
    );
};

export const getPostById = params => {
    const { id } = params;

    return axios.get(
        API + config.posts + `/${id}?populate=image&populate=content&populate=related_articles.posts&populate=tags&=`,
    );
};


export const getPostBySlug = params => {
    const { postSlug } = params;

    return axios.get(
        API + config.posts + `?populate=image&populate=content&populate=related_articles.posts.image&populate=tags&filters[slug][$eq]=${postSlug}&populate=product_widgets.image&populate=product_widgets.lists&populate=meta_tags.tags&populate=contents.language`,
    );
};
