import TrustedPartners from 'components/Sections/TrustedPartners';
import OurProducts from 'components/Sections/OurProducts/';
import Reviews from 'components/Sections/Reviews';
import GetStarted from 'components/Sections/GetStarted';
import RadioFAQ from 'components/Sections/RadioFAQ';
import Hero from './Hero';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectUseCasesPartners,
    selectUseCasesProducts,
    selectUseCasesReviews,
    selectUseCasesCta,
    selectUseCasesIsLoading,
    fetchCurrentUseCases,
    selectUseCases,
    selectUseCasesInformations,
} from 'store/slices/UseCasesSlice';
import { useEffect, useState } from 'react';
import { useImageLoaded } from 'hooks/useImageLoaded';
import { useNavigate, useParams } from 'react-router-dom';

const UseCases = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const isLoadedImages = useImageLoaded();
    const { useCasesSlug } = useParams();
    const navigate = useNavigate();

    const data = useSelector(selectUseCases);
    const partners = useSelector(selectUseCasesPartners);
    const { title, product_card, tag } = useSelector(selectUseCasesProducts);
    const reviews = useSelector(selectUseCasesReviews);
    const cta = useSelector(selectUseCasesCta);
    const useCaseIsLoading = useSelector(selectUseCasesIsLoading);
    const informations = useSelector(selectUseCasesInformations);

    useEffect(() => {
        if (useCasesSlug) {
            // @ts-ignore
            dispatch(fetchCurrentUseCases({ useCasesSlug }));
        }
    }, [dispatch, useCasesSlug]);

    useEffect(() => {
        if (isLoadedImages && !useCaseIsLoading) {
            setIsLoading(false);
        }
    }, [isLoadedImages, useCaseIsLoading]);

    if (!useCaseIsLoading && !data) {
        navigate('*');
        return null;
    }

    return (
        !useCaseIsLoading && (
            <>
                <Hero isLoading={isLoading} />
                <TrustedPartners data={partners} isLoading={isLoading} />
                <OurProducts title={title} tag={tag} products={product_card} isLoading={isLoading} />
                <RadioFAQ isLoading={isLoading} data={informations} />
                <Reviews reviews={reviews} isLoading={isLoading} />
                <GetStarted cta={cta} isLoading={isLoading} />
            </>
        )
    );
};

export default UseCases;
