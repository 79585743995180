import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getAboutUs } from 'api/AboutUs';
import { createRequest } from 'api/helpers';

export const fetchAboutUs = createRequest('fetchAboutUs', getAboutUs, (_, { getState }) => {
    const { attributes } = getState().aboutUsState.aboutUs.data;
    return !attributes;
});

const AboutUsSlice = createSlice({
    name: 'AboutUsSlice',
    initialState: {
        aboutUs: {
            data: {
                attributes: null,
            },
        },
        aboutUsIsLoading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchAboutUs.pending, state => {
            state.aboutUsIsLoading = true;
        });
        builder.addCase(fetchAboutUs.fulfilled, (state, action) => {
            state.aboutUsIsLoading = false;
            state.aboutUs = action.payload;
        });
    },
});

export const selectAboutUs = createSelector(
    [state => state.aboutUsState.aboutUs],
    aboutUs => aboutUs.data.attributes,
);

export const selectAboutUsHero = createSelector([selectAboutUs], data => data?.hero || {});
export const selectAboutUsPartners = createSelector([selectAboutUs], data => data?.partners || {});
export const selectAboutUsAbout = createSelector([selectAboutUs], data => data?.about || {});
export const selectAboutUsVision = createSelector([selectAboutUs], data => data?.vision_mission || {});
export const selectAboutUsTeam = createSelector([selectAboutUs], data => data?.team || {});
export const selectAboutUsCareer = createSelector([selectAboutUs], data => data?.career || {});
export const selectAboutUsTags = createSelector([selectAboutUs], data => data?.meta_tags || {});




export const selectAboutUsIsLoading = createSelector([state => state.aboutUsState], data => data.aboutUsIsLoading);

export default AboutUsSlice.reducer;
