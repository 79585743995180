

const extractFirstLetters = (inputText) => {
    const words = inputText.split(/[ .,_]+/);
    const result = words.reduce((acc, word) => {
        if (word.length > 0) {
            acc += word[0];
        }
        return acc;
    }, '');

    return result;
};


export default extractFirstLetters;
