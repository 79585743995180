//colors: lavender/blue/pink

export const data = {

    title: 'Satisfied customers',
    reviews: [
        {
            color: 'lavender',
            picture: "",
            name: "Nahum Daniel",
            rate: 5,
            date: 'Oct 29, 2023',
            title: 'Best Proxies',
            desc: 'Excellent proxy service. Intuitive website and you get proxies instantly on top of that they are best proxies i have used in terms of speed. The customer support is also stellar with super quick response times.',

        },
        {
            color: 'blue',
            picture: '',
            name: "Phillipe Pinto",
            rate: 5,
            date: 'Oct 16, 2023',
            title: 'These are amazing high quality proxies',
            desc: 'These are amazing high quality proxies that even work with sites with strict restrictions such as Nike. These have been my go two proxies for the last year. Fast speeds and no downtime. Their portal is also really user friendly.',
        },
        {
            color: 'pink',
            picture: '',
            name: "Kent Chung",
            rate: 5,
            date: 'Oct 2, 2023',
            title: 'Good proxies, great customer service',
            desc: 'I used their captcha proxies for over a year. I found them to be effective and reasonably priced. And whenever I had an issue, customer service was prompt and professional.',
        },
        {
            color: 'lavender',
            picture: '',
            name: "Yash A",
            rate: 5,
            date: 'Oct 5, 2023',
            title: 'The IP\'s are top quality',
            desc: 'The IP\'s are top quality, I have been using them for half a year now and there\'s been just 1 or two instances where there was a short downtime but it was taken care of instantly.The support is also great, all questions are answered no matter how silly and responses are very prompt.',
        },
        {
            color: 'blue',
            picture: '',
            name: "Chris Daberkoe",
            rate: 5,
            date: 'Jul 22, 2023',
            title: 'Ping is by far the Superior ISP provider',
            desc: 'I had used a few other providers from 21\'-22\'and beginning in 23\' I decided to give Ping a try. I was referred to them by a friend of mine and I haven\'t looked back since. I\'m impressed with the speeds at which their products run. I\'ve never experienced any downtime and their user Dashboard was very easy to navigate. I\'ve made a lot of money with Ping this year and do not plan on leaving anytime soon!',
        },
        {
            color: 'pink',
            picture: '',
            name: "Tylr_sneakers",
            rate: 5,
            date: 'Aug 14, 2023',
            title: 'High quality and reliable',
            desc: 'I have been using Ping proxies throughout my time botting and they work incredibly well. Their ISPs worked well for all things Nike related and their residential pro proxies were one of the only ones reliable and fast enough to get through the troubles adidas faced with their servers during any of the yeezy days. I’ve copped countless pairs due to the speed of these proxies and I always appreciate the constant communication on their discord server whenever there are outages (which has only happened once i can recall) or maintenance to make sure they are always working when we need them. Would 100% recommend.',
        },
        {
            color: 'lavender',
            picture: '',
            name: "Jasmine Warner",
            rate: 5,
            date: 'Dec 08, 2023',
            title: 'My experience with Ping Proxies ',
            desc: 'My experience with Ping Proxies has been nothing but delightful. They have great Proxies at great prices, when you are in a bit of a pickle the staff team is quick and professional. I have had a great experience as customer with Ping Proxies. They are the best!',
        },
        {
            color: 'blue',
            picture: '',
            name: "Jandré Cera",
            rate: 5,
            date: 'Dec 3, 2023',
            title: 'Five stars',
            desc: 'Been using them for quite some time now, I\'d say that I\'m always satisfied with their service. Customer service is top-notch. Do not hesitate to use their service.',
        },
        {
            color: 'pink',
            picture: '',
            name: "Michael Rector",
            rate: 5,
            date: 'Nov 23, 2023',
            title: 'Always a pleasure to do business',
            desc: 'Been using Ping Proxies for over a year now, and I am very satisfied with their products. The owner is very kind so it\'s always a pleasure to do business with them!',
        },
        {
            color: 'lavender',
            picture: '',
            name: "Shane Scully",
            rate: 5,
            date: 'Jul 21, 2023',
            title: 'I\'ve been using ping proxies ',
            desc: 'I\'ve been using ping proxies for the past year or so.Really impressed with their service. High-quality proxies, seamless dashboard, etc.They have great support on discord as well, never had an issue they couldn\'t solve for me!',
        },
        {
            color: 'blue',
            picture: '',
            name: "Alhamdu Ileah",
            rate: 5,
            date: 'Oct 2, 2023',
            title: 'I\'ve been a regular customer ',
            desc: 'I\'ve been a regular customer of Ping Proxies for the last year, and i only have to say good things about them.The services i\'ve tried are the following, and my experiences with them:ISP Static:Experience was overall really great. There were certain instances that the proxies would not suit my needs (them getting detected by certain websites), but support been always really fast (less than 10 minutes waiting in most cases) and they\'ve always replaced the ips without even questioning once.Proxy speed wise, they were always fast for my needs.Residential Pro:Overall experience, really great. Generated proxies worked 90% of times, with certain times where it would not connect. But a simple refresh always fixed the issue so its not something to complain about.Proxy speed wise, they were always fast for my needs.Overall rating: 100000/10. Really good service, better than most main stream ones.',
        },
        {
            color: 'pink',
            picture: '',
            name: "S.Payne",
            rate: 5,
            date: 'Jul 3, 2023',
            title: 'Affordable. Friendly service',
            desc: 'I first used ping proxies a good few years ago, since then I have been around the houses using other services and found myself back with Ping !! Their customer service get back to your queries ASAP and they have continued to provide an affordable and friendly service.',
        },

    ],
    reviews_count: 64,
    trustscore: 4.8,




}