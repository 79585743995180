import { createSelector, createSlice } from '@reduxjs/toolkit';


const uislice = createSlice({
    name: 'uislice',
    initialState: {
        isCookieOpen: false,
        isContactFormOpen: false,
        firstLoading: true,
        header: {
            isMobileMenuOpen: false,
            isHeaderBannerClosed: true,
            isFirstHeaderBanner: true,
            isScrolled: false,
            activeNavigationIndex: null,
        }
    },
    reducers: {
        setMobileMenuOpen: (state, action) => {
            state.header.isMobileMenuOpen = action.payload;
        },
        setHeaderScroll: (state, action) => {
            state.header.isScrolled = action.payload;
        },
        setFirstLoading: (state, action) => {
            state.firstLoading = action.payload;
        },
        setAgreeCookieOpen: (state, action) => {
            state.isCookieOpen = action.payload;
        },
        setHeaderBanner: (state, action) => {
            state.header.isHeaderBannerClosed = action.payload;
        },
        setFirstHeaderBanner: (state, action) => {
            state.header.isFirstHeaderBanner = action.payload;
        },
        setActiveNavigationIndex: (state, action) => {
            state.header.activeNavigationIndex = action.payload;
        },
        setContactFormOpenOpen: (state, action) => {
            state.isContactFormOpen = action.payload;
        },
    },
});

export const selectHeaderOptions = createSelector(
    [state => state.ui],
    data => data.header,
);
export const selectActiveNavigationIndex = createSelector([selectHeaderOptions], data => data?.activeNavigationIndex);
export const selectisMobileMenuOpen = createSelector([selectHeaderOptions], data => data.isMobileMenuOpen);
export const selectIsHeaderBannerClosed = createSelector([selectHeaderOptions], data => data.isHeaderBannerClosed);
export const selectFirstHeaderBanner = createSelector([selectHeaderOptions], data => data.isFirstHeaderBanner);
export const selectIsScrolled = createSelector([selectHeaderOptions], data => data.isScrolled);

export const selectIsCookieAgree = createSelector([state => state.ui], data => data.isCookieAgree);
export const selectisCookieOpen = createSelector([state => state.ui], data => data.isCookieOpen);
export const selectFirstLoading = createSelector([state => state.ui], data => data.firstLoading);
export const selectisContactFormOpen = createSelector([state => state.ui], data => data.isContactFormOpen);

export const {
    setFirstLoading,
    setHeaderScroll,
    setMobileMenuOpen,
    setFirstHeaderBanner,
    setHeaderBanner,
    setActiveNavigationIndex,
    setContactFormOpenOpen,
    setAgreeCookieOpen } = uislice.actions;

export default uislice.reducer;
