import './styles.scss';
import Button from 'components/common/Buttons/index';
import { useSelector } from 'react-redux';
import { selectFZF } from 'store/slices/GeneralSlice';
import { getImageUrl } from 'helpers';

const FZF = () => {
    const fzf = useSelector(selectFZF);
    const imageUrl = getImageUrl(fzf.image);

    return (
        <section className="inner-container fzf">
            <div className="fzf-content">
                <h1>{fzf.title}</h1>
                <div className="body1">{fzf.description}</div>
                <Button text={fzf.button_text} to={fzf.button_text_link} />
            </div>
            <div className="fzf-image">
                <img src={imageUrl} alt="" />
            </div>
        </section>
    );
};

export default FZF;
