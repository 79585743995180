import Button from 'components/common/Buttons';
import TrustpilotLogo from 'components/TrustLogo';
import TrustpilotStars from 'components/TrustpilotStars';

const ReviewsInfo = ({ trustscore, reviews_count }) => {
    return (
        <div className='reviews-trustpilot'>
            <div >
                <div className='reviews-trustpilot__upperrow'>
                    <TrustpilotLogo size={"L"} />
                    <TrustpilotStars size={"L"} />
                </div>
                <div className='reviews-trustpilot__underrow'>
                    <div className='body3 reviews-trustpilot__underrow-item'>
                        <span>TrustScore</span>{trustscore}
                    </div>
                    {/* <div className='body3 reviews-trustpilot__underrow-item'>
                        {reviews_count} <span>reviews</span>
                    </div> */}
                </div>
            </div>
            <Button text={"View all reviews"} type={"outline"} to={process.env.REACT_APP_TRUSTPILOT_URL} target={true} />
        </div>
    );
}

export default ReviewsInfo;