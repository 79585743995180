import './styles.scss';

const CheckBox = ({ type = "white", id, checked, error, onChange }) => {
    const handleCheckboxChange = (event) => {
        if (onChange) {
            onChange(event.target.checked, event.target.error);
        }
    };

    return (
        <input
            className={`checkbox checkbox--${type} ${error ? "" : "checkbox--error"}`}
            type="checkbox"
            id={id}
            checked={checked}
            onChange={handleCheckboxChange}
            required
        />
    );
};

export default CheckBox;
