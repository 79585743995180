import extractFirstLetters from './helpers'
import TrustpilotStars from 'components/TrustpilotStars';



const ReviewsItem = ({ data }) => {
    const { index, name, color, date, title, desc } = data;
    return (
        <div className="reviews-item" key={index}>
            <div className='reviews-item__header'>
                <div>
                    <div className={`reviews-item-abbreviation reviews-item-abbreviation--${color}`}>{extractFirstLetters(name)}</div>
                </div>
                <div>{name}</div>
            </div>
            <div className='reviews-item__data'>
                <TrustpilotStars />
                <div className='body3'>{date}</div>
            </div>
            <div className='reviews-item__body'>
                <div className='body1'>{title}</div>
                <div className='body2'>{desc}</div>
            </div>
        </div>
    );
}

export default ReviewsItem;