import { createAsyncThunk } from '@reduxjs/toolkit';

export const createRequest = (name, request, condition) =>
    createAsyncThunk(
        name,
        async (params, { getState }) => {
            const response = await request(params).catch(e => {
                throw e;
            });

            return response.data;
        },
        { condition },
    );
