import { createSelector, createSlice } from '@reduxjs/toolkit';
import { getBlogPage, getFilterTags, getPostBySlug, getPosts } from 'api/Blog';
import { createRequest } from 'api/helpers';

export const fetchBlogPage = createRequest('fetchBlogPage', getBlogPage, (_, { getState }) => {
    const { attributes } = getState().blogState.blog.data;
    return !attributes;
});

export const fetchPosts = createRequest('fetchPosts', getPosts);

export const fetchFilterTags = createRequest('fetchFilterTags', getFilterTags, (_, { getState }) => {
    const { data } = getState().blogState.tags;
    return !data;
});

export const fetchCurrentPost = createRequest('fetchCurrentPost', getPostBySlug);

const blogSlice = createSlice({
    name: 'blogSlice',
    initialState: {
        blog: {
            data: {
                attributes: null,
            },
        },
        blogIsLoading: false,
        posts: {},
        tags: {
            data: null,
        },
        currentPost: {
            data: {
                attributes: null,
            },
        },
        currentPostIsLoading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchBlogPage.pending, state => {
                state.blogIsLoading = true;
            })
            .addCase(fetchBlogPage.fulfilled, (state, action) => {
                state.blogIsLoading = false;
                state.blog = action.payload;
            })
            .addCase(fetchPosts.fulfilled, (state, action) => {
                state.posts = action.payload;
            })
            .addCase(fetchFilterTags.fulfilled, (state, action) => {
                state.tags = action.payload;
            })
            .addCase(fetchCurrentPost.pending, (state, action) => {
                state.currentPostIsLoading = true;
            })
            .addCase(fetchCurrentPost.fulfilled, (state, action) => {
                state.currentPostIsLoading = false;
                state.currentPost = action.payload;
            });
    },
});

export const selectBlogPage = createSelector([state => state.blogState.blog], blog => blog.data.attributes || {});

export const selectHero = createSelector([selectBlogPage], data => data?.hero || {});
export const selectBlogTags = createSelector([selectBlogPage], data => data?.meta_tags || {});


export const selectPosts = createSelector([state => state.blogState], data => data.posts);
export const selectLatestPost = createSelector([selectPosts], data => data?.data?.[0]);


export const selectFilterTags = createSelector(
    [state => state.blogState.tags],
    data => data?.data?.map(tag => tag.attributes.name) || [],
);

export const selectCurrentPost = createSelector(
    [state => state.blogState.currentPost],
    currentPost => currentPost.data[0]?.attributes || {},
);

export const selectCurrentPostRelated = createSelector([selectCurrentPost], data => data?.related_articles || {});
export const selectCurrentPostTags = createSelector([selectCurrentPost], data => data?.meta_tags || {});


export const selectCurrentPostIsLoading = createSelector([state => state.blogState], data => data.currentPostIsLoading);

export const selectGeneralIsLoading = createSelector([state => state.blogState], data => data.blogIsLoading);

export default blogSlice.reducer;
