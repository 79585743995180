import { ReactComponent as Arrow } from 'assets/icons/button/arrow.svg'
import { ReactComponent as ArrowUpRight } from 'assets/icons/button/arrow-up-right.svg'

export const getIcon = iconForType => {
    const icons = {
        purple: <Arrow className='arrow' />,
        white: <Arrow className='arrow' />,
        outline: <ArrowUpRight className='arrow' />,
        outlineAnchor: <Arrow className='arrow arrow--rotate' />,
    };

    return icons[iconForType];
};