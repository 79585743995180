import useAnalyticsEventTracker from 'hooks/useAnalyticsEventTracker';
import './styles.scss';
import { ReactComponent as User } from 'assets/icons/header/user.svg';
import { Link } from 'react-router-dom';
import { extractToltReferralUUID } from 'helpers';

const LogBtn = ({ text }) => {
    const eventTracker = useAnalyticsEventTracker({ category: 'Navigation', action: 'Login Click' });

    const toltReferralUUID = extractToltReferralUUID();

    return (
        <div className="log-btn-container">
            <Link
                to={`${process.env.REACT_APP_DASHBOARD_URL}${toltReferralUUID ? `?toltId=${toltReferralUUID}` : ''}`}
                className="log-btn"
                onClick={eventTracker}
            >
                <User />
                {text}
            </Link>
        </div>
    );
};

export default LogBtn;
