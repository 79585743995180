import { Link } from 'react-router-dom';
import { ReactComponent as Home } from 'assets/icons/House.svg';
import { ReactComponent as Arrow } from 'assets/icons/button/arrow.svg';
import './styles.scss';
import ROUTES from 'constants/routes';

const HeroCrumbs = ({ page, blog = false, locations = false }) => {
    return (
        <div className="body3 hero-bredcrumbs">
            <Link to={ROUTES.base} aria-label='Go to home'>
                <Home />
            </Link>
            <span>
                <Arrow />
            </span>
            {
                blog && <>
                    <Link to={ROUTES.blog} aria-label='Go to Blog'>Blog</Link>
                    <span>
                        <Arrow />
                    </span>
                </>
            }
            {
                locations && <>
                    <Link to={ROUTES.location} aria-label='Go to Proxy Locations'>Proxy Locations</Link>
                    <span>
                        <Arrow />
                    </span>
                </>
            }
            <span>
                <div>{page}</div>
            </span>
        </div>
    );
};

export default HeroCrumbs;
