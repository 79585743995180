import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getHomepage } from 'api/Homepage';
import { createRequest } from 'api/helpers';

export const fetchHomepage = createRequest('fetchHomepage', getHomepage, (_, { getState }) => {
    const { attributes } = getState().homepageState.homepage.data;
    return !attributes;
});

const homepageSlice = createSlice({
    name: 'homepageSlice',
    initialState: {
        homepage: {
            data: {
                attributes: null,
            },
        },
        homepageIsLoading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchHomepage.pending, state => {
            state.homepageIsLoading = true;
        });
        builder.addCase(fetchHomepage.fulfilled, (state, action) => {
            state.homepageIsLoading = false;
            state.homepage = action.payload;
        });
    },
});

export const selectHomepage = createSelector(
    [state => state.homepageState.homepage],
    homepage => homepage.data.attributes,
);

export const selectHomepageFirstScreen = createSelector([selectHomepage], data => data?.first_screen || {});
export const selectHomepagePartners = createSelector([selectHomepage], data => data?.partners || {});
export const selectHomepageProducts = createSelector([selectHomepage], data => data?.product_plans || {});
export const selectHomepageFeatures = createSelector([selectHomepage], data => data?.features || {});
export const selectHomepageDashboard = createSelector([selectHomepage], data => data?.dashboard || {});
export const selectHomepageApiIntegration = createSelector([selectHomepage], data => data?.api_integrations || []);
export const selectHomepageReviews = createSelector([selectHomepage], data => data?.reviews || {});
export const selectHomepageUsecases = createSelector([selectHomepage], data => data?.use_cases || {});
export const selectHomepageLocations = createSelector([selectHomepage], data => data?.locations || {});
export const selectHomepageAboutUs = createSelector([selectHomepage], data => data?.about_us || {});
export const selectHomepageCta = createSelector([selectHomepage], data => data?.cta || {});
export const selectHomepageTags = createSelector([selectHomepage], data => data?.meta_tags || {});

export const selectHomepageIsLoading = createSelector([state => state.homepageState], data => data.homepageIsLoading);

export default homepageSlice.reducer;
