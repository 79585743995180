import './styles.scss';
import { data } from './mockData'
import Label from 'components/Label';
import ReviewsRow from './ReviewsRow';
import ReviewsInfo from './ReviewsInfo';
import { useRef } from 'react';
import { useAnimation } from 'hooks/useAnimation';




const Reviews = ({ reviews, isLoading }) => {
    const firstRow = data.reviews.slice(0, data.reviews.length / 2);
    const secondRow = data.reviews.slice(data.reviews.length / 2);

    const container = useRef();

    useAnimation({
        items: ['.reviews h2', '.reviews-rows', '.inner-container'],
        container,
        dependencies: [isLoading],
    })

    return (
        data.reviews.length && (
            <section ref={container} className="container">
                <div className="reviews">
                    <Label text={reviews.tag} />
                    <h2>{reviews.title}</h2>
                    <div className="reviews-rows">
                        <ReviewsRow list={firstRow} />
                        <ReviewsRow list={secondRow} toRight time={60} />
                    </div>
                    <div className='inner-container'>
                        <ReviewsInfo trustscore={data.trustscore} reviews_count={data.reviews_count} />
                    </div>
                </div>
            </section>
        )
    );
};

export default Reviews;
