import Australia from "assets/flags/Australia.jpg"
import Brazil from "assets/flags/Brazil.jpg"
import Canada from "assets/flags/Canada.jpg"
import China from "assets/flags/China.jpg"
import France from "assets/flags/France.jpg"
import Germany from "assets/flags/Germany.jpg"
import India from "assets/flags/India.jpg"
import Indonesia from "assets/flags/Indonesia.jpg"
import Italy from "assets/flags/Italy.jpg"
import Spain from "assets/flags/Spain.jpg"
import UnitedKingdom from "assets/flags/UnitedKingdom.jpg"
import UnitedStates from "assets/flags/UnitedStates.jpg"


import { ReactComponent as TIA } from "assets/icons/datacenters/TIA_White.svg";
import { ReactComponent as NIST } from "assets/icons/datacenters/NIST__White.svg";
import { ReactComponent as Environmental } from "assets/icons/datacenters/environmental_White.svg";
import { ReactComponent as Energy } from "assets/icons/datacenters/energy_White.svg";
import { ReactComponent as Uniformation } from "assets/icons/datacenters/information_White.svg";
import { ReactComponent as CyberEssentials } from "assets/icons/datacenters/cyber-essentials_White.svg";
import { ReactComponent as RipeNcc } from "assets/icons/datacenters/ripe-ncc_White.svg";

export const data = {

    countries: [

        {
            flag: UnitedKingdom,
            name: 'United Kingdom',
        },
        {
            flag: UnitedStates,
            name: 'United States',
        },
        {
            flag: Germany,
            name: 'Germany',
        },
        {
            flag: France,
            name: 'France',
        },
        {
            flag: Canada,
            name: 'Canada',
        },
        {
            flag: Italy,
            name: 'Italy',
        },
        {
            flag: Spain,
            name: 'Spain',
        },
        {
            flag: Indonesia,
            name: 'Indonesia',
        },
        {
            flag: India,
            name: 'India',
        },
        {
            flag: China,
            name: 'China',
        },

        {
            flag: Brazil,
            name: 'Brazil',
        },

        {
            flag: Australia,
            name: 'Australia',
        },


    ],

    list: [
        "Over 8,056,839 IPs worldwide",
        "Ready to deploy in seconds",
        "Highly reliable with 99.9% uptime",
    ],
    media: [
        {
            desc: 'Certified data centers and upstream providers',
            items: [
                {
                    icon: <TIA />,
                },
                {
                    icon: <NIST />,
                },
                {
                    icon: <Environmental />,
                },
                {
                    icon: <Energy />,
                },
                {
                    icon: <Uniformation />,
                },
                {
                    icon: <CyberEssentials />,
                },
                {
                    icon: <RipeNcc />,
                },
            ]
        },
    ]
}