import HeaderNavigationItem from './HeaderNavigationItem';
import { useSelector } from 'react-redux';
import { selectNav } from 'store/slices/GeneralSlice';


const HeaderNavigation = () => {
    const nav = useSelector(selectNav)
    return (
        <nav className="header-nav">
            {
                nav.map((item, index) => (
                    <HeaderNavigationItem key={index} index={index} item={item} />
                ))
            }
        </nav>);
}

export default HeaderNavigation;