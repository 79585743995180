import { useEffect } from 'react';

export default () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://cdn.tolt.io/tolt.js';
        script.async = true;
        script.setAttribute('data-tolt', process.env.REACT_APP_TOLT_ID);

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return null;
};
