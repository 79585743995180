import { Link } from "react-router-dom";
import "./styles.scss"
import { ReactComponent as Arrow } from 'assets/icons/button/arrow.svg';

const LinkItem = ({ text, to = null, onClick = null }) => {
    const handleClick = () => {
        if (onClick) {
            onClick()
        }
    }

    return to ? (
        <Link to={to} className='link-item' onClick={handleClick}>{text}< Arrow /></Link>

    )
        :
        (
            <div className='link-item'>{text}< Arrow /></div >

        )




}

export default LinkItem;



