import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { selectFirstLoading } from 'store/slices/UI-Slice';
import { useSelector } from 'react-redux';


gsap.registerPlugin(ScrollTrigger);

const markers = false;

const duration = 0.4;
const ease = 'power1.in';
const y = 10;
const start = 'top 85%';

const debug = item => {
    if (!markers) {
        return {};
    } else {
        return {
            onStart: () => {
                console.log('onStart', { item });
            },
            onComplete: () => {
                console.log('onComplete', { item });
            },
        };
    }
};

const animationDefault = item =>
    gsap.from(item, {
        y: y,
        opacity: 0,
        ease: ease,
        duration: duration,
        scrollTrigger: {
            trigger: item,
            start: start,
            end: 'top',
            markers: markers,
        },
        ...debug(item),
    });

const animationWithStagger = ({ item, trigger }) =>
    gsap.from(item, {
        opacity: 0,
        ease: ease,
        duration: duration,
        stagger: 0.15,
        scrollTrigger: {
            trigger: trigger,
            start: start,
            markers: markers,
        },
        ...debug(item),
    });

const animationWithoutScroll = ({ item }) =>
    gsap.from(item, {
        y: y,
        opacity: 0,
        ease: ease,
        duration: duration,
        ...debug(item),
    });

const paralaxBg = (item, speed, index) =>
    gsap.to(item, {
        yPercent: speed[index],
        ease: 'none',
        scrollTrigger: {
            trigger: item,
            scrub: 1,
            markers: markers,
        },
        ...debug(item),
    });


const defaultItems = ['.has-animation', '.label'];


export const useAnimationWithoutDefaltsItems = ({ items, container, dependencies = [] }) => {
    if (markers) {
        console.log('useAnimation', items, container);
    }
    useGSAP(
        () => {
            let targets = gsap.utils.toArray(items);
            targets.forEach(item => animationDefault(item));
        },
        { dependencies, scope: container, revertOnUpdate: true },
    );
};
export const useAnimation = ({ items, container, dependencies = [] }) => {
    if (markers) {
        console.log('useAnimation', items, container);
    }
    const withDefaultDependencies = [...dependencies];
    useGSAP(
        () => {
            let targets = gsap.utils.toArray([...defaultItems, ...items]);
            targets.forEach(item => animationDefault(item));
        },
        { dependencies: withDefaultDependencies, scope: container, revertOnUpdate: true },
    );
};

export const useAnimationWithStagger = ({ item, container, trigger, dependencies = [] }) => {
    if (markers) {
        console.log('useAnimationWithStagger', item, container);
    }

    useGSAP(
        () => {
            animationWithStagger({ item, trigger });
        },
        { dependencies, scope: container, revertOnUpdate: true },
    );
};





export const useAnimationWithWithoutScroll = ({ item, container, dependencies = [], isLoading }) => {
    if (markers) {
        console.log('useAnimationWithWithoutScroll', item, container);
    }
    const withDefaultDependencies = [isLoading, ...dependencies];
    useGSAP(
        () => {
            if (!isLoading) {
                animationWithoutScroll({ item });
            }
        },
        { dependencies: withDefaultDependencies, scope: container, revertOnUpdate: true },
    );
};


export const useAnimationBgParalax = ({ items, container, dependencies = [] }) => {
    if (markers) {
        console.log('useAnimation', items, container);
    }
    const speed = [-75];

    const firstLoading = useSelector(selectFirstLoading)
    const withDefaultDependencies = [firstLoading, ...dependencies];
    useGSAP(
        () => {
            let targets = gsap.utils.toArray(items);
            targets.forEach((item, index) => paralaxBg(item, speed, index));
        },
        { dependencies: withDefaultDependencies, scope: container, revertOnUpdate: true },
    );
};