import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getUseCasesBySlag } from 'api/UseCases';
import { createRequest } from 'api/helpers';

// export const fetchUseCases = createRequest('fetchUseCases', getUseCases, (_, { getState }) => {
//     const { attributes } = getState().useCasesState.useCases.data;
//     return !attributes;
// });

export const fetchCurrentUseCases = createRequest('useCasesSlice', getUseCasesBySlag);

const useCasesSlice = createSlice({
    name: 'useCasesSlice',
    initialState: {
        useCases: {
            data: {
                attributes: null,
            },
        },
        useCasesIsLoading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchCurrentUseCases.pending, state => {
            state.useCasesIsLoading = true;
        });
        builder.addCase(fetchCurrentUseCases.fulfilled, (state, action) => {
            state.useCasesIsLoading = false;
            state.useCases = action.payload;
        });
        builder.addCase(fetchCurrentUseCases.rejected, (state, action) => {
            state.useCasesIsLoading = false;
        });
    },
});

export const selectUseCases = createSelector(
    [state => state.useCasesState.useCases],
    useCases => useCases.data[0]?.attributes,
);


export const selectUseCasesHero = createSelector([selectUseCases], data => data?.hero || {});
export const selectUseCasesPartners = createSelector([selectUseCases], data => data?.partners || {});
export const selectUseCasesProducts = createSelector([selectUseCases], data => data?.product_plans || {});
export const selectUseCasesInformations = createSelector([selectUseCases], data => data?.information_section || {});
export const selectUseCasesReviews = createSelector([selectUseCases], data => data?.reviews || {});
export const selectUseCasesCta = createSelector([selectUseCases], data => data?.cta || {});
export const selectUseCasesTags = createSelector([selectUseCases], data => data?.meta_tags || {});



export const selectUseCasesIsLoading = createSelector([state => state.useCasesState], data => data.useCasesIsLoading);

export default useCasesSlice.reducer;