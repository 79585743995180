import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectFooter } from 'store/slices/GeneralSlice';

const FooterInfo = () => {

    const footer = useSelector(selectFooter)
    if (Object.entries(footer).length === 0) return

    return (
        <div className="footer-info">
            <div className="footer-info__copyright">
                <div className="body3">{footer.copyright_text}</div>
                <div className="body3">{footer.operated_text}</div>
            </div>
            <ul className="body3 footer-info__docs">
                {
                    footer.legal_info_links.map((item, index) => (
                        <li key={index} className="footer-info__docs-item">
                            <Link to={item.link}>{item.text}</Link>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
};

export default FooterInfo;
