import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRightUp } from 'assets/icons/button/arrow-up-right.svg';
import { ReactComponent as Mail } from 'assets/icons/socials/Mail.svg';
import { ReactComponent as Discord } from 'assets/icons/socials/Discord.svg';
import useMediaQuery from 'hooks/useMediaQuery';
import { selectFooter } from 'store/slices/GeneralSlice';
import { useSelector } from 'react-redux';
import { getImageUrl } from 'helpers';
import ROUTES from 'constants/routes';

const FooterNav = () => {
    const isTablet = useMediaQuery('max-width: 768px');
    const footer = useSelector(selectFooter)
    const logoUrl = getImageUrl(footer.logo)
    if (Object.entries(footer).length === 0) return
    const nav = [
        {
            name: footer.products_section,
            links: footer.product_items,
        },
        {
            name: footer.features_section,
            links: footer.features_items,
        },
        {
            name: footer.company_section,
            links: footer.company_items,
        }
    ]

    return (
        <div className="footer-menu">
            <div className="footer-menu_logo-nav">
                <div className="footer-logo">
                    <Link to={ROUTES.base}>
                        <img src={logoUrl} alt="Ping" />
                    </Link>
                </div>
                <a href={`mailto:${footer.email} `} className="footer-menu__social">
                    <div className="footer-menu__social-icon">
                        <Mail />
                    </div>
                    <div className="link footer-menu__social-name">{footer.email}</div>
                </a>
                <Link to={'/'} className="footer-menu__social">
                    <div className="footer-menu__social-icon">
                        <Discord />
                    </div>
                    <div className="link footer-menu__social-name">{footer.discord}</div>
                </Link>
            </div>
            {isTablet &&
                <div>
                    <div className="link footer-media-title">
                        {footer.social_section}
                    </div>
                    <div className="footer-media__social-items">
                        {
                            footer.social_items?.map((item, index) => (
                                <Link to={item.link ? item.link : "#"} key={index} className="footer-media__social-icon">
                                    <img src={getImageUrl(item.image)} alt={item.image.data.attributes.alternativeText} />
                                </Link>
                            ))
                        }
                    </div>
                </div>
            }
            <nav className="footer-menu__nav-container">
                {
                    nav?.map((item, index) => (
                        <div key={index} className="footer-menu__nav">
                            <div>
                                <div className="description2">{item.name}</div>
                                {item.links.map((item, index) => (
                                    <Link key={index} to={item.link} className={`link ${item.underline ? "footer-menu__nav-underline" : ""}`}>
                                        {item.text} {item.in_new_tab && <ArrowRightUp />}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    ))
                }
            </nav>
        </div>
    );
};

export default FooterNav;
