import './styles.scss'
import ContactForm from "components/ContactForm";
import { data } from './mockData'
import HeroTrustpilot from 'components/Sections/Hero/HeroTrustpilot';
import { ReactComponent as ListIcon } from 'assets/icons/check-list.svg'
import { ReactComponent as Exit } from 'assets/icons/cross.svg';
import useMediaQuery from 'hooks/useMediaQuery';
import { selectContactForm } from 'store/slices/GeneralSlice';
import { useSelector } from 'react-redux';
import { getImageUrl } from 'helpers';
import { selectisContactFormOpen, setContactFormOpenOpen } from 'store/slices/UI-Slice';
import { useDispatch } from 'react-redux';


const ContactsPopup = () => {
    const isTablet = useMediaQuery('max-width: 1024px');
    const isOpen = useSelector(selectisContactFormOpen);
    const dispatch = useDispatch()
    const { left_banner_title,
        // left_banner_description,
        left_banner_certificate_text,
        left_banner_certificate_items,
    } = useSelector(selectContactForm)

    if (!left_banner_title &&
        !left_banner_certificate_text &&
        !left_banner_certificate_items) return null

    const handleAskExpertClick = () => {
        // document.body.classList.toggle('dark');
        document.documentElement.classList.toggle('unscrolled');
        dispatch(setContactFormOpenOpen(false))
    };
    return (
        <>
            <section className={`contacts-popup ${isOpen ? "contacts-popup--open" : "contacts-popup--close"}`}>
                {!isTablet &&
                    <div className='contacts-popup-info'>
                        <HeroTrustpilot type={'outline'} />
                        <div className='description1'>{left_banner_title} </div>
                        <ul className='contacts-popup-info-list'>
                            {
                                data.list.map((list, index) => (
                                    <li key={index}>
                                        <ListIcon className={`contacts-popup-info-list__icon`} />
                                        <div className='body4'>{list}</div>
                                    </li>))
                            }
                        </ul >

                        <div className="contacts-popup-info__datacenter">

                            <div >
                                <div className="body4 contacts-popup-info__datacenter-title">{left_banner_certificate_text}</div>
                                <div className="contacts-popup-info__datacenter-items">
                                    {left_banner_certificate_items?.map((subItem, index) => (
                                        <span key={index}>
                                            <img src={getImageUrl(subItem.image)} alt="" />
                                        </span>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>
                }

                <ContactForm />
                <div className='contacts-popup-exit' onClick={handleAskExpertClick}><Exit /></div>
            </section>
            <div onClick={handleAskExpertClick} className={`contacts-popup-layer ${isOpen ? "contacts-popup-layer--open" : "contacts-popup-layer--close"}`}></div>
        </>
    );
}

export default ContactsPopup;