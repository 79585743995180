import { ReactComponent as Arrow } from 'assets/icons/button/arrow.svg';
// import useMediaQuery from 'hooks/useMediaQuery';
import { NavLink } from 'react-router-dom';

const HeaderNavigationName = ({ name, link, hasList, onMouseEnter, onClick }) => {
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return hasList ? (
        <div
            className="header-nav__item-name"
            onClick={handleClick}
            onMouseEnter={onMouseEnter}
        >
            <span>{name}</span>
            {hasList && <Arrow className="header-nav__item-name_arrow" />}
        </div>
    ) : (
        <NavLink to={link} className="header-nav__item-name"
            onClick={handleClick}
            onMouseEnter={onMouseEnter}>
            <span>{name}</span>
            {hasList && <Arrow className="header-nav__item-name_arrow" />}
        </NavLink>
    );
};

export default HeaderNavigationName;
