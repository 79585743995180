import TrustpilotStars from 'components/TrustpilotStars';
import TrustpilotLogo from 'components/TrustLogo';
import './styles.scss'
import { Link } from 'react-router-dom';


//Type: green/outline

const HeroTrustpilot = ({ type = "green" }) => {
    return (
        <Link to={process.env.REACT_APP_TRUSTPILOT_URL} target='_blank' className={`body3 hero-trustpilot hero-trustpilot--${type}`}>
            <span>Excellent</span>
            <TrustpilotStars />
            {/* <div className='hero-trustpilot__reviews'>
                {reviews}
                <span>reviews on</span>
            </div> */}
            <TrustpilotLogo type={type} />
        </Link>
    );
}

export default HeroTrustpilot;