import axios from 'axios';
import config from 'api/urls-config.json';
import { API, headers } from 'api/constants';

export const getGeneral = () => {
    return axios.get(API + config.general);
};

export const postContactForm = ({ data }) => {
    return axios.post(API + config.contact_form, data, { headers });
};

export const postNewsLetterSubscribe = ({ data }) => {
    return axios.post(API + config.subscriber, data, { headers });
};

