import React from 'react';
import './styles.scss';
import Label from 'components/Label';
import List from './ProductCardList';
import Button from 'components/common/Buttons';
import { Link } from 'react-router-dom';
import { getImageUrl } from 'helpers';
import useAnalyticsEventTracker from 'hooks/useAnalyticsEventTracker';

const ProductCard = ({ product }) => {
    const color = 'red';
    const {
        title,
        description,
        price_starting_text,
        price_tag_text,
        price_price_text,
        price_ip_text,
        button_text,
        button_link,
        icon,
        list_items,
        color_list_icon,
    } = product;
    const URL = getImageUrl(icon)

    return (
        <Link to={button_link} className="product-card" onClick={useAnalyticsEventTracker({ category: "User", action: "Buy Now" })}>
            <div className={`${color}-icon product-card__icon`}><img src={URL} alt="" /></div>
            <div className="product-card__info">
                <div className="description2 product-card__info-title">{title}</div>
                <div className="body2 product-card__info-desc">{description}</div>
            </div>
            <div className='product-card__body'>
                <div className="product-card__price-container">
                    <div className="product-card__text-container">
                        <div className="body3">{price_starting_text}</div>
                        <div className="button-text">{price_price_text}</div>
                        <div className="body3">{price_ip_text}</div>
                    </div>
                    {price_tag_text && <Label text={price_tag_text} />}
                </div>
                <div className='product-card__body-2'>
                    <List color={color_list_icon} lists={list_items} />

                    <Button text={button_text} />
                </div>

            </div>
        </Link>
    );
};

export default ProductCard;
