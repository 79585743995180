import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getFAQ } from 'api/FAQ';
import { createRequest } from 'api/helpers';

export const fetchFAQ = createRequest('fetchFAQ', getFAQ, (_, { getState }) => {
    const { attributes } = getState().faqState.faq.data;
    return !attributes;
});

const FAQSlice = createSlice({
    name: 'FAQSlice',
    initialState: {
        faq: {
            data: {
                attributes: null,
            },
        },
        faqIsLoading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchFAQ.pending, state => {
            state.faqIsLoading = true;
        });
        builder.addCase(fetchFAQ.fulfilled, (state, action) => {
            state.faqIsLoading = false;
            state.faq = action.payload;
        });
    },
});

export const selectFAQ = createSelector(
    [state => state.faqState.faq],
    faq => faq.data.attributes,
);

export const selectFAQSections = createSelector([selectFAQ], data => data?.faq.question_section || []);
export const selectFAQTags = createSelector([selectFAQ], data => data?.meta_tags || {});





export const faqIsLoading = createSelector([state => state.faqState], data => data.faqIsLoading);

export default FAQSlice.reducer;
