import { ReactComponent as Google } from 'assets/icons/googleIcon.svg';
import { useState } from 'react';
import Button from '..';
import { generateURL } from 'api/GoogleAuth';
import { extractToltReferralUUID } from 'helpers';

const GoogleButton = () => {
    const [loading, setLoading] = useState(false);

    const handleClick = async () => {
        try {
            setLoading(true);
            const params = {
                authorization_type: 'signup',
            };
            const toltReferralUUID = extractToltReferralUUID();
            if (toltReferralUUID) {
                params.customer_tolt_referral_id = toltReferralUUID;
            }
            const redirect_url = generateURL(params);
            window.open(redirect_url, '_self');
        } catch (e) {
            return false;
        } finally {
            setLoading(false);
        }
    };
    return (
        <Button
            hasArrow={false}
            onClick={handleClick}
            disabled={loading}
            type="outline"
            text={
                <>
                    <Google />
                    <span style={{ marginLeft: 5 }}>Start with Google</span>
                </>
            }
        />
    );
};

export default GoogleButton;
