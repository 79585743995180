import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getContact } from 'api/Contact';
import { createRequest } from 'api/helpers';

export const fetchContact = createRequest('fetchContact', getContact, (_, { getState }) => {
    const { attributes } = getState().contactState.contact.data;
    return !attributes;
});

const ContactSlice = createSlice({
    name: 'ContactsSlice',
    initialState: {
        contact: {
            data: {
                attributes: null,
            },
        },
        contactIsLoading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchContact.pending, state => {
            state.contactIsLoading = true;
        });
        builder.addCase(fetchContact.fulfilled, (state, action) => {
            state.contactIsLoading = false;
            state.contact = action.payload;
        });
    },
});

export const selectContact = createSelector(
    [state => state.contactState.contact],
    contact => contact.data.attributes,
);



export const contactIsLoading = createSelector([state => state.contactState], data => data.contactIsLoading);
export const selectContactTags = createSelector([selectContact], data => data?.meta_tags || {});


export default ContactSlice.reducer;
