import React from "react";
import './styles.scss';
import FooterNav from "./FooterNav";
import FooterMedia from "./FooterMedia";
import FooterInfo from "./FooterInfo";

const Footer = () => {
    return (
        <>

            <footer className="footer">
                    <FooterNav />
                    <FooterMedia />
                    <hr className="line" />
                    <FooterInfo />
            </footer >
        </>

    );
}

export default Footer;