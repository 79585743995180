// import useAnalyticsEventTracker from "hooks/useAnalyticsEventTracker";
import "./styles.scss"
import { ReactComponent as Download } from 'assets/icons/download.svg'




const DownloadButton = ({ text = null, type = "default", size = "M", onClick = null }) => {
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };
    return (
        <button className={`download-button download-button--${type}  download-button--${size}`} onClick={handleClick}>
            {
                type === "default" && text
            }
            <Download />
        </button>
    )
}






export default DownloadButton;