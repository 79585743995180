// import './styles.scss'

import { useImageLoaded } from 'hooks/useImageLoaded';
import HeroBrand from './Hero';
import LogotypeSection from './Logotype';
import MarkSection from './Mark';
import ColoursSection from './Colours';
import TypographySection from './Typography/';

const BrandAssets = () => {

    const isLoadedImages = useImageLoaded();




    return isLoadedImages && (
        <>
            <HeroBrand isLoading={isLoadedImages} />
            <LogotypeSection isLoading={isLoadedImages} />
            <MarkSection isLoading={isLoadedImages} />
            <ColoursSection isLoading={isLoadedImages} />
            <TypographySection isLoading={isLoadedImages} />
        </>
    );
}

export default BrandAssets;