import './styles.scss'

import { ReactComponent as Typography } from 'assets/images/brand/Typography.svg'
import { ReactComponent as Aa } from 'assets/images/brand/Aa.svg'

import { useRef } from 'react';
import DownloadButton from 'components/common/Buttons/DownloadButton';
import { useAnimation } from 'hooks/useAnimation';


const TypographySection = ({ isLoading }) => {

    const container = useRef();
    useAnimation({
        items: ['.brand-assets-typography__title',
            '.brand-assets-typography__description',
            '.brand-assets-typography-item-head',
            '.bgFont',
            '.brand-assets-typography-item',
            '.brand-assets-typography-item-weight li',
            '.brand-assets-typography-item-symbols'],
        container,
        dependencies: [isLoading],
    })

    const data = {
        title: "Typography",
        description: "Use the Space Grotesk font from the Google fonts library in all textual materials for better brand consistency",

    }

    return (
        <section ref={container} className="inner-container brand-assets-typography">
            <h2 className='brand-assets-typography__title h2'>{data.title}</h2>
            <p className='brand-assets-typography__description body1'>{data.description}</p>
            <div className='brand-assets-typography__wrapper'>
                <div className='brand-assets-typography-item'>
                    <div className='brand-assets-typography-item-head'>
                        <div className='h3'>Space Grotesk</div>
                        <DownloadButton text={"Download font"} size='S' />
                    </div>
                    <div className='bgFont'><Aa /></div>
                </div>
                <div className='brand-assets-typography-item'>

                    <div className='brand-assets-typography-item-symbols'>
                        <Typography />
                    </div>

                    <ul className='brand-assets-typography-item-weight'>
                        <li>Space Grotesk - Light</li>
                        <li>Space Grotesk - Regular</li>
                        <li>Space Grotesk - Medium</li>
                        <li>Space Grotesk - Bold</li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default TypographySection;