import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getGeneral } from 'api/General';
import { createRequest } from 'api/helpers';
import ROUTES from 'constants/routes';
import { selectDatacenterProxies, selectResidentialProxies, selectStaticResidentialProxies } from './PricingSlice';
import { selectFeatures } from './FeaturesSlice';

export const fetchGeneral = createRequest('fetchGeneral', getGeneral);

const generalSlice = createSlice({
    name: 'generalSlice',
    initialState: {
        general: {
            data: {
                attributes: null,
            },
        },
        isCookiesAgree: false,
        generalIsLoading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchGeneral.pending, state => {
            state.generalIsLoading = true;
        });
        builder.addCase(fetchGeneral.fulfilled, (state, action) => {
            state.generalIsLoading = false;
            state.general = action.payload;
        });
    },
});

export const selectGeneral = createSelector(
    [state => state.generalState.general],
    general => general.data.attributes || {},
);

export const selectSubscribe = createSelector([selectGeneral], general => general.post_subscribe || {});
export const selectHeader = createSelector([selectGeneral], general => general.header || {});
export const selectNav = createSelector([selectHeader], data => {
    return [
        {
            name: data.products,
            items: {
                datacenter_section: data.datacenter_section,
                residential_section: data.residential_section,
                datacenter_items: data.datacenter_items,
                residential_items: data.residential_items,
            },
        },
        {
            name: data.features,
            items: {
                our_features_sesction: data.our_features_sesction,
                locations_section: data.locations_section,
            },
        },
        {
            name: data.company,
            items: {
                company_section: data.company_section,
                resources_section: data.resources_section,
                latest_news_section: data.latest_news_section,
                company_items: data.company_items,
                resources_items: data.resources_items,
            },
        },
        {
            name: data.pricing,
            link: ROUTES.pricing,
        },
    ];
});

export const selectBreadcrumbList = createSelector([selectHeader, selectFeatures], (headerData, featureData) => {
    const itemList = [
        ...(headerData.datacenter_items || []).filter(item => item.link && item.link !== '/'),
        ...(headerData.residential_items || []).filter(item => item.link && item.link !== '/'),
        ...(headerData.company_items || []).filter(item => item.link && item.link !== '/'),
        ...(headerData.resources_items || []).filter(item => item.link && item.link !== '/'),
        ...(ROUTES.pricing && ROUTES.pricing !== '/' ? [{ title: headerData.pricing, link: ROUTES.pricing }] : []),
        ...(ROUTES.contacts && ROUTES.contacts !== '/' ? [{ title: headerData.contacts, link: ROUTES.contacts }] : []),
        ...(featureData || [])
            .filter(
                feature =>
                    feature.attributes.preview_title && feature.attributes.slug && feature.attributes.slug !== '/',
            )
            .map(feature => ({
                title: feature.attributes.preview_title,
                link: 'features/' + feature.attributes.slug,
            })),
    ];

    return itemList.map(item => ({
        title: item.title,
        link: item.link,
    }));
});

export const selectFooter = createSelector([selectGeneral], general => general.footer || {});
export const selectFZF = createSelector([selectGeneral], general => general.page_404 || {});
export const selectCookies = createSelector([selectGeneral], general => general.cookeis || {});
export const selectBanner = createSelector([selectGeneral], general => general.banner || {});
export const selectContactForm = createSelector([selectGeneral], general => general.contacts_form || {});
export const selectProductTable = createSelector([selectGeneral], general => general.compare_products || {});
export const selectGeneralTags = createSelector([selectGeneral], data => data?.meta_tags || {});
export const selectBrandAssetsTags = createSelector([selectGeneral], data => data?.brand_assets_meta_tags || {});

export const selectProductCalc = createSelector([selectGeneral], general => general.product_pricing || {});

export const selectCurrentDatacenterProxies = createSelector(
    [selectDatacenterProxies, selectProductCalc],
    (productData, general) => {
        const data = general.products?.find(item => item.unique_id === productData.product_id);
        return data;
    },
);
export const selectCurrentStaticResidentialProxies = createSelector(
    [selectStaticResidentialProxies, selectProductCalc],
    (productData, general) => {
        const data = general.products?.find(item => item.unique_id === productData.product_id);
        return data;
    },
);
export const selectCurrentResidentialProxies = createSelector(
    [selectResidentialProxies, selectProductCalc],
    (productData, general) => {
        const data = general.products?.find(item => item.unique_id === productData.product_id);
        return data;
    }
);

export const selectIsCookiesAgree = createSelector([selectGeneral], general => general.cookeis || {});
export const selectGeneralIsLoading = createSelector([state => state.generalState], data => data.generalIsLoading);

export default generalSlice.reducer;
