import { Outlet } from 'react-router-dom';
import './styles.scss';
import Footer from './Footer';
import Header from './Header';
import ContactsPopup from 'components/Popups/ContactsPopup';
import Cookies from 'components/Popups/Cookies';
import { useSelector } from 'react-redux';
import { selectIsHeaderBannerClosed } from 'store/slices/UI-Slice';
import { Suspense } from 'react';
import LoadingOverlay from 'components/common/LoadingOverlay';

const Layout = props => {
    const isHeaderBannerClosed = useSelector(selectIsHeaderBannerClosed);
    return (
        <>
            <Header />
            <main className={`${isHeaderBannerClosed ? '' : 'with-banner'}`}>
                <Suspense fallback={<LoadingOverlay />}>
                    <Outlet />
                </Suspense>
            </main>
            <Suspense fallback={<LoadingOverlay />}>
                <Cookies />
                <ContactsPopup />
            </Suspense>
            <Footer />
        </>
    );
};

export default Layout;
