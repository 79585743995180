import React, { useState } from 'react';
import { ReactComponent as Question } from 'assets/icons/header/question.svg';
import './styles.scss';
import { handleMavaClick } from 'helpers';

const AskExpert = ({ text }) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <button onClick={() => handleMavaClick(isLoading, setIsLoading)} className="ask-expert" disabled={isLoading}>
            <Question />
            {isLoading ? 'Loading...' : text}
        </button>
    );
};

export default AskExpert;
