import './style.scss'
import Label from "components/Label";
import Button from "components/common/Buttons";
import { useAnimation, useAnimationBgParalax } from 'hooks/useAnimation';
import { useRef } from 'react';
import getStartedBg from 'assets/backgrounds/get-started.svg'
import getStartedBgMob from 'assets/backgrounds/getStartedBgMob.svg'
import useMediaQuery from 'hooks/useMediaQuery';


const GetStarted = ({ cta, isLoading }) => {
    const isTablet = useMediaQuery('max-width: 768px')

    const container = useRef();

    useAnimation({
        items: ['.getstarted h2', '.getstarted-button'],
        container,
        dependencies: [],
    })

    useAnimationBgParalax({
        items: ['.getstarted__bg'],
        container,
        dependencies: [isLoading],
    })



    return (
        <section ref={container} className="container getstarted">
            <Label text={cta.tag} type="outline" />
            <h2 className="h1">{cta.title}</h2>
            <div className='getstarted-button'>
                <Button type="white" text={cta.button_text} to={cta.button_link} target={true} />
            </div>
            <img className='getstarted__bg' src={isTablet ? getStartedBgMob : getStartedBg} alt="" />
        </section>
    );
}

export default GetStarted;