import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getPricing, getProductsData } from 'api/Pricing';
import { createRequest } from 'api/helpers';

export const fetchPricing = createRequest('fetchPricing', getPricing, (_, { getState }) => {
    const { attributes } = getState().pricingState.pricing.data;
    return !attributes;
});

export const fetchProductsData = createRequest('fetchProductsData', getProductsData, (_, { getState }) => {
    const { pingProductsData } = getState().pricingState;
    return !pingProductsData;
});

const PricingSlice = createSlice({
    name: 'PricingSlice',
    initialState: {
        pricing: {
            data: {
                attributes: null,
            },
        },
        pricingIsLoading: false,
        pingProductsData: null,
        pingProductsIsLoading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchPricing.pending, state => {
                state.pricingIsLoading = true;
            })
            .addCase(fetchPricing.fulfilled, (state, action) => {
                state.pricingIsLoading = false;
                state.pricing = action.payload;
            });
        builder
            .addCase(fetchProductsData.pending, state => {
                state.pingProductsIsLoading = true;
            })
            .addCase(fetchProductsData.fulfilled, (state, action) => {
                state.pingProductsIsLoading = false;
                state.pingProductsData = action.payload.data;
            });
    },
});

export const selectPricing = createSelector([state => state.pricingState.pricing], pricing => pricing.data.attributes);

export const selectPricingTitle = createSelector([selectPricing], data => data?.title || '');
export const selectPricingDescription = createSelector([selectPricing], data => data?.description || '');
export const selectPricingCTA = createSelector([selectPricing], data => data?.cta || {});
export const selectPricingCompare = createSelector([selectPricing], data => data?.compare_products || {});
export const selectPricingCTAFooter = createSelector([selectPricing], data => data?.cta_footer || {});
export const selectPricingPayments = createSelector([selectPricing], data => data?.payments || {});
export const selectPricingFAQ = createSelector([selectPricing], data => data?.faq || {});
export const selectPricingTags = createSelector([selectPricing], data => data?.meta_tags || {});


export const selectPricingIsLoading = createSelector([state => state.pricingState], data => data.pricingIsLoading);

export const selectPingProducts = createSelector(
    [state => state.pricingState.pingProductsData],
    data => data
); // Returns all products from product/search endpoint

export const selectPingProductsIsLoading = createSelector(
    [state => state.pricingState],
    data => data.pingProductsIsLoading,
);

export const selectDatacenterProxies = createSelector(
    [selectPingProducts],
    products => {
        const foundProduct = products?.find(product => product.product_type === 'datacenter' && product.country_id === 'us');
        if (!foundProduct) {
            return {};
        }
        return foundProduct;
    }
);
export const selectStaticResidentialProxies = createSelector(
    [selectPingProducts],
    products => {
        const foundProduct = products?.find(product => product.product_type === 'isp' && product.country_id === 'us');
        if (!foundProduct) {
            return {};
        }
        return foundProduct;
    }
);
export const selectResidentialProxies = createSelector(
    [selectPingProducts],
    products => {
        const foundProduct = products?.find(product => product.product_type === 'residential');
        if (!foundProduct) {
            return {};
        }
        return foundProduct;
    }
);

export default PricingSlice.reducer;
