import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getFeatures, getFeaturesBySlag } from 'api/Features';
import { createRequest } from 'api/helpers';

export const fetchFeatures = createRequest('fetchFeatures', getFeatures, (_, { getState }) => {
    const { attributes } = getState().featuresState.features.data;
    return !attributes;
});

export const fetchCurrentFeature = createRequest('fetchCurrentFeature', getFeaturesBySlag);

const FeaturesSlice = createSlice({
    name: 'FeaturesSlice',
    initialState: {
        features: {
            data: [],
        },
        featuresIsLoading: false,
        currentFeature: {
            data: {
                attributes: null,
            },
        },
        currentFeatureIsLoading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchFeatures.pending, state => {
            state.featuresIsLoading = true;
        });
        builder.addCase(fetchFeatures.fulfilled, (state, action) => {
            state.featuresIsLoading = false;
            state.features = action.payload;
        });
        builder.addCase(fetchCurrentFeature.pending, (state, action) => {
            state.currentFeatureIsLoading = true;
        })
        builder.addCase(fetchCurrentFeature.fulfilled, (state, action) => {
            state.currentFeatureIsLoading = false;
            state.currentFeature = action.payload;
        });
    },
});

export const selectFeatures = createSelector(
    [state => state.featuresState.features],
    features => features?.data || [],
);

export const selectCurrentFeatures = createSelector(
    [state => state.featuresState.currentFeature],
    currentFeature => currentFeature.data[0]?.attributes,
);

export const selectCurrentFeaturesHero = createSelector([selectCurrentFeatures], data => data?.hero || {});
export const selectCurrentFeaturesStatistic = createSelector([selectCurrentFeatures], data => data?.statistics || {});
export const selectCurrentFeaturesSingle = createSelector([selectCurrentFeatures], data => data?.single_feature || []);
export const selectCurrentFeaturesBenefits = createSelector([selectCurrentFeatures], data => data?.benefits || {});
export const selectCurrentFeaturesUseCases = createSelector([selectCurrentFeatures], data => data?.use_cases || {});
export const selectCurrentFeaturesFAQ = createSelector([selectCurrentFeatures], data => data?.faq || {});
export const selectCurrentFeaturesCTA = createSelector([selectCurrentFeatures], data => data?.cta || {});
export const selectCurrentFeaturesTags = createSelector([selectCurrentFeatures], data => data?.meta_tags || {});



export const selectFeaturesIsLoading = createSelector([state => state.featuresState], data => data.featuresIsLoading);
export const selectCurrentFeatureIsLoading = createSelector([state => state.featuresState], data => data.currentFeatureIsLoading);

export default FeaturesSlice.reducer;
