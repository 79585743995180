import React from 'react';
import ReviewsItem from './ReviewsItem';
import useMediaQuery from 'hooks/useMediaQuery';

const getFillList = (list, copyTimes = 1) => {
    let newlist = [];
    for (let i = 0; i < copyTimes; i++) {
        newlist.push(...list);
    }
    return newlist;
};

const ReviewsRow = ({ list, time = 50, toRight = false }) => {
    const isMobile = useMediaQuery('max-width: 640px');
    const marqueeContainer = React.useRef(null);
    const marqueeArea = React.useRef(null);
    const [moveLeft, setMoveLeft] = React.useState(0);
    const [showList, setShowList] = React.useState(list);
    const [realTime, setRealTime] = React.useState(time);
    React.useEffect(() => {
        const containerWidth = Math.floor(marqueeContainer.current.offsetWidth);
        //const areaWidth = Math.floor(marqueeArea.current.scrollWidth);
        const areaWidth = Math.floor((436 + 20) * list.length);
        const copyTimes = Math.max(2, Math.ceil((containerWidth * 2) / areaWidth));
        const newMoveLeft = areaWidth * Math.floor(copyTimes / 2);
        const newRealTime = time * parseFloat((newMoveLeft / containerWidth).toFixed(2));

        setShowList(getFillList(list, copyTimes));
        setMoveLeft(newMoveLeft);
        if (isMobile) {
            setRealTime(newRealTime / 3);
        } else {
            setRealTime(newRealTime);
        };
    }, [list, time, isMobile]);


    return (
        <div className="reviews-container" ref={marqueeContainer}>
            <div
                className="reviews-area"
                ref={marqueeArea}
                style={{
                    transform: `translateX(-${moveLeft}px)`,
                    animationDuration: `${realTime}s`,
                    animationDirection: `${toRight ? ' reverse' : ''}`,
                }}
            >
                {showList.map((item, index) => (
                    <ReviewsItem key={index} data={item} />
                ))}
            </div>
        </div>
    );
};

export default ReviewsRow;
