import './styles.scss'
import { ReactComponent as Star } from 'assets/icons/trustpilot/trustpilot-star.svg'
import { ReactComponent as Trustpilot } from 'assets/icons/trustpilot/trustpilot.svg'


const TrustpilotLogo = ({ size = "M", type = "green" }) => {
    return (
        <div className={`trustpilot-logo trustpilot-logo--${size}`}>
            <Star className={`trustpilot-logo__star trustpilot-logo__star--${type}`} />
            <Trustpilot className={`trustpilot-logo__name trustpilot-logo__name--${type}`} />
        </div>
    );
}

export default TrustpilotLogo;