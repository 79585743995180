import { getImageUrl } from "helpers";


const TrustedPartners = ({ icon }) => {
    const URL = getImageUrl(icon.image);
    return (
        <div className='trusted-partners-item'>
            <img src={URL} alt="" />
        </div>
    );
}

export default TrustedPartners;