const ROUTES = {
    base: '/',
    product: '/proxy-service',
    company: '/company',
    features: '/features',
    pricing: '/pricing',
    location: '/proxies-by-location',
    contacts: '/contact',
    blog: '/blog',
    about_us: '/about-us',
    faq: '/faq',
    terms_and_conditions: '/terms-and-conditions',
    privacy_policy: '/privacy-policy',
    refund_policy: '/refund-policy',
    landing_page: '/other-proxies',
    use_cases: '/use-cases',
    brand_assets: '/brand-assets',
    ui_kit: '/ui-kit',
    not_found: '/page-not-found'
};
export default ROUTES;
