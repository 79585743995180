import { getImageUrl } from 'helpers';
import useMediaQuery from 'hooks/useMediaQuery';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectFooter } from 'store/slices/GeneralSlice';

const FooterMedia = () => {
    const isTablet = useMediaQuery('max-width: 768px');
    const footer = useSelector(selectFooter)
    if (Object.entries(footer).length === 0) return
    return (
        <div className="footer-media">
            <div className="footer-media__datacenter">
                <div>
                    <div className="link footer-media-title">{footer.sertificate_section}</div>
                    <div className="footer-media__datacenter-items">
                        {footer.certificate_items.map((item, index) => (
                            item.link ?
                                <Link to={item.link} aria-label={item.image.data.attributes.alternativeText} key={index}>
                                    <img src={getImageUrl(item.image)}
                                        alt={item.image.data.attributes.alternativeText}
                                    />
                                </Link>
                                :
                                <div key={index}>
                                    <img src={getImageUrl(item.image)}
                                        alt={item.image.data.attributes.alternativeText}
                                    />
                                </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="footer-media__social">
                {!isTablet &&
                    <div>
                        <div className="link footer-media-title">{footer.social_section}</div>
                        <div className="footer-media__social-items">
                            {footer.social_items.map((item, index) => (
                                item.link ?
                                    <Link key={index} to={item.link} aria-label={item.image.data.attributes.alternativeText} className="footer-media__social-icon">
                                        <img src={getImageUrl(item.image)}
                                            aria-label={item.link}
                                            alt={item.image.data.attributes.alternativeText}
                                        />
                                    </Link>
                                    :
                                    <div key={index} className="footer-media__social-icon">
                                        <img src={getImageUrl(item.image)}
                                            alt={item.image.data.attributes.alternativeText}
                                        />
                                    </div>
                            ))}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default FooterMedia;
