import HeroLayout from 'components/Sections/Hero/HeroLayout';
import './styles.scss';
import { useSelector } from 'react-redux';
import HeroComponent from './heroComponent';
import { selectUseCasesHero, selectUseCasesTags } from 'store/slices/UseCasesSlice';
import { generateMetaTags, getImageUrl } from 'helpers';
import { Helmet } from 'react-helmet';

const Hero = ({ isLoading }) => {
    const { title, description, button_text, image, button_two_text, button_text_link, button_two_text_link } =
        useSelector(selectUseCasesHero);
    const useCasesTags = useSelector(selectUseCasesTags);
    const metaTags = generateMetaTags(useCasesTags?.tags, 'meta', `Ping Proxies - ${title}`,  typeof description !== 'undefined' ? description : undefined);
    const metaTitle = generateMetaTags(useCasesTags?.tags, 'title');

    return (
        <>
            <Helmet title={title || metaTitle} meta={[...(metaTags || []),{ name: 'title', content: `Ping Proxies - ${metaTitle || title}`  },]} />
            <HeroLayout image={<img src={getImageUrl(image)} alt="Use cases illustration" />} isLoading={isLoading}>
                <HeroComponent
                    title={title}
                    desc={description}
                    button_text={button_text}
                    button_two_text={button_two_text}
                    hasCrumbs={true}
                    button_text_link={button_text_link}
                    button_two_text_link={button_two_text_link}
                    isLoading={isLoading}
                />
            </HeroLayout>
        </>
    );
};

export default Hero;
