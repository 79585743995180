import React, { useEffect } from 'react';
import { ReactComponent as BannerClose } from 'assets/icons/cross-white.svg';
import './styles.scss';
import HeaderNavigation from './HeaderNavigation';
import AskExpert from 'components/AskExpert';
import LogBtn from 'components/common/Buttons/LogButton';
import useMediaQuery from 'hooks/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';
import { selectBanner, selectHeader } from 'store/slices/GeneralSlice';
import { getImageUrl } from 'helpers';
import ROUTES from 'constants/routes';
import { Link } from 'react-router-dom';
import { selectActiveNavigationIndex, selectFirstHeaderBanner, selectIsHeaderBannerClosed, selectIsScrolled, selectisMobileMenuOpen, setActiveNavigationIndex, setFirstHeaderBanner, setHeaderBanner, setHeaderScroll, setMobileMenuOpen } from 'store/slices/UI-Slice';

const Header = () => {
    const dispatch = useDispatch()
    const isTablet = useMediaQuery('max-width: 1024px');
    const header = useSelector(selectHeader)
    const banner = useSelector(selectBanner)
    const isMobileMenuOpen = useSelector(selectisMobileMenuOpen)
    const activeIndex = useSelector(selectActiveNavigationIndex);
    const isHeaderBannerClosed = useSelector(selectIsHeaderBannerClosed)
    const isFirstHeaderBanner = useSelector(selectFirstHeaderBanner)
    const isHeaderScrolled = useSelector(selectIsScrolled)
    const handleClick = () => dispatch(setHeaderBanner(!isHeaderBannerClosed));




    const onToggleMobileMenu = () => {
        dispatch(setMobileMenuOpen(!isMobileMenuOpen));
        dispatch(setActiveNavigationIndex(null));
        if (isMobileMenuOpen) {
            document.documentElement.classList.remove('unscrolled');
            document.body.classList.remove('dark');
            if (window.scrollY > 0) {
                dispatch(setHeaderScroll(!isHeaderScrolled));
            } else {
                dispatch(setHeaderScroll(false));
            }

        } else {
            document.documentElement.classList.add('unscrolled');
            document.body.classList.add('dark');
            dispatch(setHeaderScroll(false));
        }

    };

    const closeMenu = () => {
        dispatch(setMobileMenuOpen(false));
        dispatch(setActiveNavigationIndex(null));
        document.documentElement.classList.remove('unscrolled');
        document.body.classList.remove('dark');
        if (window.scrollY > 0) {
            dispatch(setHeaderScroll(!isHeaderScrolled));
        } else {
            dispatch(setHeaderScroll(false));
        }
    }

    useEffect(() => {

        const handleScroll = () => {
            if (activeIndex === null && !isMobileMenuOpen) {
                if (window.scrollY > 0) {
                    dispatch(setHeaderScroll(true));
                } else {
                    dispatch(setHeaderScroll(false));
                }
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [dispatch, activeIndex, isMobileMenuOpen]);

    useEffect(() => {
        if (isFirstHeaderBanner && isHeaderBannerClosed && banner.is_active) {
            const showHeaderBanner = setTimeout(() => {
                dispatch(setHeaderBanner(!isHeaderBannerClosed));
                dispatch(setFirstHeaderBanner(false));
            }, 5000);
            return () => {
                clearTimeout(showHeaderBanner);
            };
        }
    }, [dispatch, isFirstHeaderBanner, isHeaderBannerClosed, banner.is_active])

    const handleMouseLeave = () => {
        if (!isTablet) {
            document.body.classList.remove('dark');
            if (window.scrollY > 0) {
                dispatch(setHeaderScroll(true));
            }
            dispatch(setActiveNavigationIndex(null));
        }
    };

    const logoUrl = getImageUrl(header.logo)

    if (Object.entries(header).length === 0) return

    return (
        <header className={`header ${isHeaderBannerClosed ? "" : "header-with-banner"}  ${isHeaderScrolled ? "scrolled" : ""}`}
            onMouseLeave={handleMouseLeave}>
            {
                !isHeaderBannerClosed && banner.is_active && (
                    banner.link ? (
                        <div className='header-price-info body3'>
                            <Link to={banner.link} className='header-price-info-container' onClick={closeMenu}>
                                <span>{banner.text}</span>
                            </Link>
                            <button onClick={handleClick} aria-label='Close header banner' className='header-price-info-close'>
                                <BannerClose />
                            </button>
                        </div>
                    ) : (
                        <div className='header-price-info body3'>
                            <div className='header-price-info-container'>
                                <span>{banner.text}</span>
                            </div>
                            <button onClick={handleClick} aria-label='Close header banner' className='header-price-info-close'>
                                <BannerClose />
                            </button>
                        </div>
                    )
                )
            }
            <div className="header-container">
                <Link to={ROUTES.base} className="header-logo" onClick={closeMenu}>
                    <img src={logoUrl} alt="Ping" />
                </Link>
                {isTablet ? (
                    <>
                        <button
                            className={`burger-button ${isMobileMenuOpen ? 'active' : ''}`}
                            onClick={onToggleMobileMenu}
                            aria-label='Open navigation menu'
                        >
                            <div className="burger-button-line top-line"></div>
                            <div className="burger-button-line middle-line"></div>
                            <div className="burger-button-line bottom-line"></div>
                        </button>
                        <div className={`header-mobile-menu ${isMobileMenuOpen ? 'header-mobile-menu--open' : 'header-mobile-menu--close'} ${isHeaderBannerClosed ? '' : 'header-mobile-menu--with-banner'}`}>
                            <div className="header-log">
                                <AskExpert text={header.ask_expert} />
                                <LogBtn text={header.log_in} />
                            </div>
                            <HeaderNavigation />
                        </div>
                    </>
                ) : (
                    <>
                        <HeaderNavigation />
                        <div className="header-log">
                            <AskExpert text={header.ask_expert} />
                            <LogBtn text={header.log_in} />
                        </div>
                    </>
                )}
            </div>
        </header>
    );
};

export default Header;
