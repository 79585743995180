import axios from 'axios';
import config from 'api/urls-config.json';
import { API } from 'api/constants';

export const getTermsAndConditions = () => {
    return axios.get(API + config.pages.terms_and_conditions);
};

export const getPrivacyPolicy = () => {
    return axios.get(API + config.pages.privacy_policy);
};

export const getRefundPolicy = () => {
    return axios.get(API + config.pages.refund_policy);
};