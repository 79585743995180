import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getPrivacyPolicy, getTermsAndConditions, getRefundPolicy } from 'api/LegalInfo';
import { createRequest } from 'api/helpers';

export const fetchTermsAndConditions = createRequest(
    'fetchTermsAndConditions',
    getTermsAndConditions,
    (_, { getState }) => {
        const { attributes } = getState().legalState.termsAndConditions.data;
        return !attributes;
    },
);
export const fetchPrivacyPolicy = createRequest('fetchPrivacyPolicy', getPrivacyPolicy, (_, { getState }) => {
    const { attributes } = getState().legalState.privacyPolicy.data;
    return !attributes;
});

export const fetchRefundPolicy = createRequest('fetchRefundPolicy', getRefundPolicy, (_, { getState }) => {
    const { attributes } = getState().legalState.refundPolicy.data;
    return !attributes;
});

const legalSlice = createSlice({
    name: 'legalSlice',
    initialState: {
        termsAndConditions: {
            data: {
                attributes: null,
            },
        },
        termsAndConditionsIsLoading: false,
        privacyPolicy: {
            data: {
                attributes: null,
            },
        },
        privacyPolicyIsLoading: false,
        refundPolicy: {
            data: {
                attributes: null,
            },
        },
        refundPolicyIsLoading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchTermsAndConditions.pending, state => {
                state.termsAndConditionsIsLoading = true;
            })
            .addCase(fetchTermsAndConditions.fulfilled, (state, action) => {
                state.termsAndConditionsIsLoading = false;
                state.termsAndConditions = action.payload;
            });
        builder
            .addCase(fetchPrivacyPolicy.pending, state => {
                state.privacyPolicyIsLoading = true;
            })
            .addCase(fetchPrivacyPolicy.fulfilled, (state, action) => {
                state.privacyPolicyIsLoading = false;
                state.privacyPolicy = action.payload;
            });

        builder
            .addCase(fetchRefundPolicy.pending, state => {
                state.refundPolicyIsLoading = true;
            })

            .addCase(fetchRefundPolicy.fulfilled, (state, action) => {
                state.refundPolicyIsLoading = false;
                state.refundPolicy = action.payload;
            });
    },
});

export const selectTermsAndConditions = createSelector(
    [state => state.legalState.termsAndConditions],
    termsAndConditions => termsAndConditions.data.attributes || {},
);

export const selectTermsAndConditionsIsLoading = createSelector(
    [state => state.legalState],
    data => data.termsAndConditionsIsLoading,
);

export const selectTermsAndConditionsTags = createSelector([selectTermsAndConditions], data => data?.meta_tags || {});

export const selectPrivacyPolicy = createSelector(
    [state => state.legalState.privacyPolicy],
    privacyPolicy => privacyPolicy.data.attributes || {},
);

export const selectPrivacyPolicyIsLoading = createSelector(
    [state => state.legalState],
    data => data.privacyPolicyIsLoading,
);

export const selectPrivacyPolicyTags = createSelector([selectPrivacyPolicy], data => data?.meta_tags || {});

export const selectRefundPolicy = createSelector(
    [state => state.legalState.refundPolicy],
    refundPolicy => refundPolicy.data.attributes || {},
);

export const selectRefundPolicyIsLoading = createSelector(
    [state => state.legalState],
    data => data.refundPolicyIsLoading,
);

export const selectRefundPolicyTags = createSelector([selectRefundPolicy], data => data?.meta_tags || {});

export default legalSlice.reducer;
