import axios from 'axios';
import config from 'api/urls-config.json';
import { API } from 'api/constants';

export const getLocations = () => {
    return axios.get(API + config.locations);
};

export const getContinents = () => {
    return axios.get(API + config.continents);
};

export const getLocationsPage = () => {
    return axios.get(API + config.pages.locations);
};

export const getCurrentLocations = ({ countrySlug }) => {
    return axios.get(API + `/countries?filters[slug][$eq]=${countrySlug}&populate=flag&populate=hero.image&populate=products_plans.product_card.icon&populate=proxy_locations.proxy_table&populate=single_feature.image&populate=reviews&populate=benefits.cards.icon&populate=faq.question_section.question&populate=cta&populate=continent&populate=products_plans.product_card.list_items&populate=meta_tags.tags`);
};

