import './styles.scss'

import { useEffect, useRef } from 'react';
import { useAnimation } from 'hooks/useAnimation';


const ColoursSection = ({ isLoading }) => {

    const container = useRef();
    const containerGradiend = useRef();
    const degLine = useRef(null);
    useAnimation({
        items: ['.brand-assets-colours__title',
            '.brand-assets-colours__description',
            '.brand-assets-colours__primary .title',
            '.brand-assets-colours-item',
            '.brand-assets-colours-gradient',
            '.brand-assets-colours__secondary .title'],
        container,
        dependencies: [isLoading],
    })

    const data = {
        title: "Colours",
        description: "If you want to use our brand colours in your materials about Ping Proxies, please make sure to check the colour codes.",
        primary: {
            title: "Primary colors",
            colors: [
                {
                    title: "Light grey",
                    params: [
                        {
                            name: "HEX",
                            code: "#F2F3F8"
                        },
                        {
                            name: "RGB",
                            code: "242  243  248"
                        },
                    ]
                },
                {
                    title: "Grey",
                    params: [
                        {
                            name: "HEX",
                            code: "#E5E7F1"
                        },
                        {
                            name: "RGB",
                            code: "229  231  241"
                        },
                    ]
                },
                {
                    title: "Dark blue",
                    params: [
                        {
                            name: "HEX",
                            code: "#0D1126"
                        },
                        {
                            name: "RGB",
                            code: "13  17  38"
                        },
                    ]
                },
                {
                    title: "Blue-purple",
                    params: [
                        {
                            name: "HEX",
                            code: "#5547EB"
                        },
                        {
                            name: "RGB",
                            code: "85  71  235"
                        },
                    ]
                },
                {
                    title: "Lavender",
                    params: [
                        {
                            name: "HEX",
                            code: "#A192EC"
                        },
                        {
                            name: "RGB",
                            code: "161  145  236"
                        },
                    ]
                },
            ]
        },
        gradient: {
            title: "Brand gradient",
            deg: 225,
            colors: [
                "#A192EC",
                "#5547EB"

            ]
        },
        secondary: {
            title: "Secondary colors",
            colors: [
                {
                    title: "Blue",
                    params: [
                        {
                            name: "HEX",
                            code: "#8ABDF9"
                        },
                        {
                            name: "RGB",
                            code: "138  189  249"
                        },
                    ]
                },
                {
                    title: "Pink",
                    params: [
                        {
                            name: "HEX",
                            code: "#D993F7"
                        },
                        {
                            name: "RGB",
                            code: "217  147  247"
                        },
                    ]
                },
                {
                    title: "Green",
                    params: [
                        {
                            name: "HEX",
                            code: "#00B67A"
                        },
                        {
                            name: "RGB",
                            code: "0  182  122"
                        },
                    ]
                },
            ]
        }

    }

    useEffect(() => {
        const updateDegLineStyles = () => {
            if (containerGradiend.current && degLine.current) {
                // @ts-ignore
                const containerWidth = containerGradiend.current.offsetWidth;
                // @ts-ignore
                const containerHeight = containerGradiend.current.offsetHeight;
                const diagonalLength = Math.sqrt(Math.pow(containerWidth, 2) + Math.pow(containerHeight, 2));

                const padding = 24;
                const circleWidth = 32;

                const adjacentSide = containerWidth - 2 * padding - circleWidth;
                const oppositeSide = containerHeight - 2 * padding - circleWidth;
                const deg = Math.atan(oppositeSide / adjacentSide) * (180 / Math.PI);

                const lineLength = diagonalLength - 2 * padding - circleWidth;

                const leftOffset = (containerWidth - lineLength) / 2;


                degLine.current.style.width = `${lineLength}px`;
                degLine.current.style.transform = `rotate(-${deg}deg)`;
                degLine.current.style.left = `${leftOffset}px`;
            }
        };

        updateDegLineStyles();

        window.addEventListener('resize', updateDegLineStyles);

        return () => {
            window.removeEventListener('resize', updateDegLineStyles);
        };
    }, [data.gradient]);









    const gradientString = `linear-gradient(${data.gradient.deg}deg, ${data.gradient.colors.join(', ')})`;


    return (
        <section ref={container} className="inner-container brand-assets-colours">
            <h2 className='brand-assets-colours__title h2'>{data.title}</h2>
            <p className='brand-assets-colours__description body1'>{data.description}</p>
            <div className='brand-assets-colours__wrapper'>
                <div className='brand-assets-colours-section brand-assets-colours__primary'>
                    <p className='title body1'>{data.primary.title}</p>
                    <div className='brand-assets-colours-primary-container'>
                        {
                            data.primary.colors.map((item, index) => (
                                <div className='brand-assets-colours-item body3' key={index}>
                                    <p className='name'>{item.title}</p>
                                    <ul>
                                        {
                                            item.params.map((item, index) => (
                                                <li key={index}> {item.name} — {item.code}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className='brand-assets-colours-gradient' style={{
                    background: gradientString
                }} ref={containerGradiend}>
                    <p className='title body1'>{data.gradient.title}</p>
                    <div className='color-code body3'>{data.gradient.colors[0]}</div>
                    <div className='color-code body3'>{data.gradient.colors[1]}</div>
                    <div className='deg-line' ref={degLine}>
                        <div className='circle'></div>
                        <div className='line'></div>
                        <div className='circle'></div>
                    </div>
                </div>

                <div className='brand-assets-colours-section  brand-assets-colours__secondary'>
                    <p className='title body1'>{data.secondary.title}</p>
                    <div className='brand-assets-colours-secondary-container'>
                        {
                            data.secondary.colors.map((item, index) => (
                                <div className='brand-assets-colours-item body3' key={index}>
                                    <p className='name'>{item.title}</p>
                                    <ul>
                                        {
                                            item.params.map((item, index) => (
                                                <li key={index}>{item.name} — {item.code}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section >
    );
}

export default ColoursSection;