import axios from 'axios';
import config from 'api/urls-config.json';
import { API } from 'api/constants';

export const getFeatures = () => {
    return axios.get(API + config.features);
};


export const getFeaturesById = params => {
    const { featuresId } = params;
    return axios.get(
        API + `/features/${featuresId}?populate=preview_icon&populate=hero.image&populate=statistics.card.icon&populate=single_feature.image&populate=benefits.cards.icon&populate=use_cases.use_case_card.image&populate=faq.question_section.question&populate=cta&populate=faq_question_section.question`,
    );
};

export const getFeaturesBySlag = params => {
    const { featuresSlug } = params;
    return axios.get(
        API + `/features?filters[slug][$eq]=${featuresSlug}&populate=preview_icon&populate=hero.image&populate=statistics.card.icon&populate=single_feature.image&populate=benefits.cards.icon&populate=use_cases.use_case_card.image&populate=faq.question_section.question&populate=cta&populate=faq_question_section.question&populate=meta_tags.tags`,
    );
};

