import React, { lazy, Suspense } from 'react';
import HeaderNavigationName from './HeaderNavigationName';
import useMediaQuery from 'hooks/useMediaQuery';
import { selectActiveNavigationIndex, selectIsScrolled, setActiveNavigationIndex, setHeaderScroll, setMobileMenuOpen } from 'store/slices/UI-Slice';
import { useDispatch, useSelector } from 'react-redux';
// import HeaderNavigationDropdown from './HeaderNavigationDropdown';

const HeaderNavigationDropdown = lazy(() => import('./HeaderNavigationDropdown'));

const HeaderNavigationItem = ({ item, index }) => {
    const dispatch = useDispatch();
    const activeIndex = useSelector(selectActiveNavigationIndex);
    const isHeaderScrolled = useSelector(selectIsScrolled)
    const isTablet = useMediaQuery('max-width: 1111px');

    const handleMouseEnter = () => {
        if (item.items && !isTablet) {
            dispatch(setActiveNavigationIndex(index));
            document.body.classList.add('dark');
            dispatch(setHeaderScroll(false));
        } else {
            document.body.classList.remove('dark');
            if (window.scrollY > 0) {
                dispatch(setHeaderScroll(true));
            }
            dispatch(setActiveNavigationIndex(null));
        }
    };

    const handleClick = () => {
        if (item.items) {
            if (!isTablet) {
                document.body.classList.toggle('dark');
                if (window.scrollY > 0) {
                    dispatch(setHeaderScroll(!isHeaderScrolled));
                } else {
                    dispatch(setHeaderScroll(false));
                }
            }
            if (activeIndex === null) {
                dispatch(setActiveNavigationIndex(index));
            } else {
                dispatch(setActiveNavigationIndex(null));
                document.documentElement.classList.remove('unscrolled');

            }
        } else {
            if (isTablet) {
                dispatch(setMobileMenuOpen(false));
                document.documentElement.classList.remove('unscrolled');
            }
            document.body.classList.remove('dark');
            if (window.scrollY > 0) {
                dispatch(setHeaderScroll(true));
            }
            dispatch(setActiveNavigationIndex(null));
        }
    };

    return (
        <div
            className={`header-nav__item ${index === activeIndex ? 'active' : ''}`}
        >
            <HeaderNavigationName link={item.link} onMouseEnter={handleMouseEnter} name={item.name} hasList={!!item.items} onClick={handleClick} />
            <Suspense>
                <HeaderNavigationDropdown item={item} isDropdownOpen={index === activeIndex} onClick={handleClick} />
            </Suspense>
        </div>
    );
};

export default HeaderNavigationItem;
