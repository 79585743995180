import './style.scss'
import { ReactComponent as Star } from 'assets/icons/trustpilot/trustpilot-star.svg'

const starsCount = ({ size }) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
        stars.push(
            <div key={i} className={`trustpilot-rate__star trustpilot-rate__star--${size}`}>
                <Star />
            </div>
        );
    }
    return stars;
};

//size: M/L

const TrustpilotStars = ({ size = "M" }) => {

    return (
        <div className='trustpilot-rate'>
            {starsCount({ size })}
        </div >
    );
}

export default TrustpilotStars;