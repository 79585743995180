import './styles.scss';
import ProductCard from 'components/ProductCard';
import Label from 'components/Label';
import { useRef } from 'react';
import { useAnimation, useAnimationWithoutDefaltsItems } from 'hooks/useAnimation';

const OurProducts = ({ title, tag, products, isLoading }) => {
    const container = useRef();

    useAnimation({
        items: ['.our-products__title',],
        container,
        dependencies: [isLoading],
    })


    useAnimationWithoutDefaltsItems({
        items: ['.product-card'],
        container,
        dependencies: [isLoading],
    })

    return (
        <section ref={container} className="our-products">
            <div className="our-products__label">
                <Label text={tag} />
            </div>
            <h2 className="inner-container our-products__title">{title}</h2>
            <div className="inner-container">
                <div className="our-products__container">
                    {products?.map(product => (
                        <ProductCard key={product.title} product={product} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default OurProducts;
