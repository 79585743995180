import { getImageUrl } from 'helpers';
import './styles.scss';
import { useSelector } from 'react-redux';
import { selectCookies } from 'store/slices/GeneralSlice';
import Button from 'components/common/Buttons/index';
import LinkItem from 'components/common/Link/index';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga4';


const Cookies = () => {
    const [cookies, setCookie] = useCookies(['cookieAgreement']);
    const { title, description, button_text, button_two_text, button_two_text_link, image } = useSelector(selectCookies);
    const isCookieAgree = cookies.cookieAgreement || false;
    const handleClick = () => {
        setCookie('cookieAgreement', true, { path: '/' });
        ReactGA.initialize(`${process.env.REACT_APP_GA_TAG}`);
    }
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (!isCookieAgree) {
            const timer = setTimeout(() => {
                setIsActive(true);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [isCookieAgree]);

    const imageUrl = getImageUrl(image);

    return !isCookieAgree && (
        <div className='cookies-container'>
            <div className={`cookies ${isActive ? 'active' : ''}`}>
                <div className='cookies-header'>
                    <div className='cookies-header__image'><img src={imageUrl} alt="" /></div>
                    <div className='description2'>{title}</div>
                </div>
                <div className='cookies-body body2 '>{description}</div>
                <div className='cookies-buttons'>
                    <Button text={button_text} onClick={handleClick} />
                    <LinkItem text={button_two_text} to={button_two_text_link} />
                </div>
            </div>
        </div>

    );
}

export default Cookies;
